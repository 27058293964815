import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Rating, ThemeProvider, Grid, Avatar } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { connect } from 'react-redux';
import { deleteReview } from '../../actions/map';
import { getReviewerInfo } from '../../actions/profile';
import CommentModal from './CommentModal';
import { useTranslation } from 'react-i18next';

const Comment = ({
	comment,
	creationDate,
	rating,
	author,
	user,
	id,
	deleteReview,
}) => {
	const { t } = useTranslation();
	const date = new Date(creationDate);
	const [authorInfo, setAuthorInfo] = useState();
	const [commentModalOpen, setCommentModalOpen] = useState(false);

	useEffect(() => {
		if (author) {
			const fetchData = async () => {
				const info = await getReviewerInfo(author);
				setAuthorInfo(info);
			};

			fetchData();
		}
	}, [author])

	const onChangeClick = () => {
		setCommentModalOpen(true);
	}

	const onDeleteClick = () => {
		deleteReview(id);
	}

	const authorButtons = () => {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1
			}}>
				<Button
					variant='contained'
					color='success'
					size='small'
					onClick={onChangeClick}
				>
					{t('comment.buttons.author.button1')}
				</Button>
				<Button
					variant='contained'
					color='error'
					size='small'
					onClick={onDeleteClick}
				>
					{t('comment.buttons.author.button2')}
				</Button>
			</Box>
		);
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			{
				commentModalOpen ? <CommentModal
					open={commentModalOpen}
					setOpen={setCommentModalOpen}
					rid={id}
					title={'TEST'}
					rating={rating}
					reviewMessage={comment}
				/> : <></>
			}

			<Box sx={{ p: 1 }}>
				<Grid
					container
					spacing={2}
				>
					<Grid item>
						<Avatar
							alt="Avatar"
							src={authorInfo?.avatar}
							sx={{
								boxShadow: 2,
							}}
						/>
					</Grid>
					<Grid
						justifyContent="left"
						item xs zeroMinWidth
					>
						<Typography
							variant='body1'
							sx={{ fontWeight: 600 }}
						>
							{authorInfo?.user_name}
						</Typography>
						<Typography variant='body2'>
							{comment}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								gap: '.15rem',
								py: 0.5,
							}}
						>
							<Rating
								value={rating}
								readOnly
								precision={0.5}
								size='small'
							/>
							<Typography variant='body2'>
								{rating?.toFixed(1)}
							</Typography>
						</Box>
						<Typography
							variant='body2'
							color='text.secondary'
						>
							{t('comment.posted')} {date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
						</Typography>
					</Grid>
				</Grid>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 1,
				}}>
					{
						user?.id === authorInfo?.user ?
							authorButtons() :
							<></>
					}
				</Box>
			</Box>
		</ThemeProvider>
	);
}

const mapStateToProps = state => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { deleteReview })(Comment);