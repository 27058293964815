import * as React from 'react';
import { styled, alpha, ThemeProvider } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.white.dark, 0.8),
	'&:hover': {
		backgroundColor: alpha(theme.palette.white.dark, 0.95),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(3),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

export default function MyEventSearch({
	searchText,
	setSearchText,	
}) {
	const { t } = useTranslation();

	return (
		<ThemeProvider theme={defaultTheme}>
			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				py: 3.5,
			}}>
				<Search>
					<SearchIconWrapper>
						<SearchIcon />
					</SearchIconWrapper>
					<StyledInputBase
						placeholder={t('searchbar.placeholder')}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</Search>
			</Box>
		</ThemeProvider>
	);
}
