import axios from 'axios';
import {
	EVENTS_LOADED_SUCCESS,
	EVENTS_LOADED_FAIL,
	MY_EVENTS_LOADED_SUCCESS,
	MY_EVENTS_LOADED_FAIL,
	POST_EVENT_SUCCESS,
	POST_EVENT_FAIL,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAIL,
	RESET_POST_EVENT_SUCCESS,
	RESET_DELETE_EVENT_SUCCESS,
	EVENT_EDITED_SUCCESS,
	EVENT_EDITED_FAIL,
	RESET_EVENT_EDITED_SUCCESS,

	POST_REVIEW_SUCCESS,
	POST_REVIEW_FAIL,
	LOAD_REVIEWS_SUCCESS,
	LOAD_REVIEWS_FAIL,
	DELETE_REVIEW_SUCCESS,
	DELETE_REVIEW_FAIL,
	RESET_POST_REVIEW_SUCCESS,
	RESET_DELETE_REVIEW_SUCCESS,
	REVIEW_EDITED_SUCCESS,
	REVIEW_EDITED_FAIL,
	RESET_REVIEW_EDITED_SUCCESS,
} from './types';
const API_URL = 'http://ec2-13-51-55-7.eu-north-1.compute.amazonaws.com/api';

export const loadEvents = () => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.get(`${API_URL}/events/`, config);
			dispatch({
				type: EVENTS_LOADED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: EVENTS_LOADED_FAIL
			});
		}
	} else {
		dispatch({
			type: EVENTS_LOADED_FAIL
		});
	}
}

export const loadMyEvents = (page, isExpired, title) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.get(`${API_URL}/events/my/?page=${page}&is_expired=${isExpired}&title=${title}`, config);
			dispatch({
				type: MY_EVENTS_LOADED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: MY_EVENTS_LOADED_FAIL
			});
		}
	} else {
		dispatch({
			type: MY_EVENTS_LOADED_FAIL
		});
	}
}


export const postEvent = (title, description, startDate, endDate, image, positionX, positionY) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		const formData = new FormData();
		formData.append('title', title);
		formData.append('description', description);
		formData.append('start_date', startDate);
		formData.append('end_date', endDate);
		if (image) {
			formData.append('image', image);
		}
		formData.append('position_x', positionX);
		formData.append('position_y', positionY);

		try {
			const response = await axios.post(`${API_URL}/events/`, formData, config);

			dispatch({
				type: POST_EVENT_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: POST_EVENT_FAIL
			});
		}
	} else {
		dispatch({
			type: POST_EVENT_FAIL
		});
	}
}

export const deleteEvent = (eid) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.delete(`${API_URL}/events/${eid}/`, config);

			dispatch({
				type: DELETE_EVENT_SUCCESS,
				payload: response.status
			});
		} catch (err) {
			dispatch({
				type: DELETE_EVENT_FAIL
			});
		}
	} else {
		dispatch({
			type: DELETE_EVENT_FAIL
		});
	}
}

export const editEvent = (eid, title, description, startDate, endDate, image) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		const formData = new FormData();
		formData.append('title', title);
		formData.append('description', description);
		formData.append('start_date', startDate);
		formData.append('end_date', endDate);
		if (image) {
			formData.append('image', image);
		}

		try {
			const response = await axios.put(`${API_URL}/events/${eid}/`, formData, config);

			dispatch({
				type: EVENT_EDITED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: EVENT_EDITED_FAIL
			});
		}
	} else {
		dispatch({
			type: EVENT_EDITED_FAIL
		});
	}
}


export const loadReviews = (eid) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.get(`${API_URL}/events/${eid}/reviews/`, config);

			dispatch({
				type: LOAD_REVIEWS_SUCCESS,
				payload: response.data.results
			});
		} catch (err) {
			dispatch({
				type: LOAD_REVIEWS_FAIL
			});
		}
	} else {
		dispatch({
			type: LOAD_REVIEWS_FAIL
		});
	}
}

export const postReview = (eid, comment, rating) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		const body = JSON.stringify({ rating, comment });

		try {
			const response = await axios.post(`${API_URL}/events/${eid}/reviews/`, body, config);

			dispatch({
				type: POST_REVIEW_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: POST_REVIEW_FAIL
			});
		}
	} else {
		dispatch({
			type: POST_REVIEW_FAIL
		});
	}
}

export const editReview = (rid, comment, rating) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		const body = JSON.stringify({ rating, comment });

		try {
			const response = await axios.put(`${API_URL}/events/reviews/${rid}/`, body, config);

			dispatch({
				type: REVIEW_EDITED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: REVIEW_EDITED_FAIL
			});
		}
	} else {
		dispatch({
			type: REVIEW_EDITED_FAIL
		});
	}
}

export const deleteReview = (rid) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.delete(`${API_URL}/events/reviews/${rid}/`, config);

			dispatch({
				type: DELETE_REVIEW_SUCCESS,
				payload: response.status
			});
		} catch (err) {
			dispatch({
				type: DELETE_REVIEW_FAIL
			});
		}
	} else {
		dispatch({
			type: DELETE_REVIEW_FAIL
		});
	}
}

export const resetPostEventSuccess = () => {
	return {
		type: RESET_POST_EVENT_SUCCESS,
	};
};

export const resetPostReviewSuccess = () => {
	return {
		type: RESET_POST_REVIEW_SUCCESS,
	};
};

export const resetDeleteReviewSuccess = () => {
	return {
		type: RESET_DELETE_REVIEW_SUCCESS,
	};
};

export const resetEditReviewSuccess = () => {
	return {
		type: RESET_REVIEW_EDITED_SUCCESS,
	};
};


export const resetDeleteEventSuccess = () => {
	return {
		type: RESET_DELETE_EVENT_SUCCESS,
	};
};

export const resetEditEventSuccess = () => {
	return {
		type: RESET_EVENT_EDITED_SUCCESS,
	};
};