export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

export const EVENTS_LOADED_SUCCESS = 'EVENTS_LOADED_SUCCESS';
export const EVENTS_LOADED_FAIL = 'EVENTS_LOADED_FAIL';
export const MY_EVENTS_LOADED_SUCCESS = 'MY_EVENTS_LOADED_SUCCESS';
export const MY_EVENTS_LOADED_FAIL = 'MY_EVENTS_LOADED_FAIL';

export const PROFILE_EDITED_SUCCESS = 'PROFILE_EDITED_SUCCESS';
export const PROFILE_EDITED_FAIL = 'PROFILE_EDITED_FAIL';
export const RESET_PROFILE_EDITED_SUCCESS = 'RESET_PROFILE_EDITED_SUCCESS';

export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';

export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS';
export const LOAD_REVIEWS_FAIL = 'LOAD_REVIEWS_FAIL';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAIL = 'POST_REVIEW_FAIL';
export const RESET_POST_REVIEW_SUCCESS = 'RESET_POST_REVIEW_SUCCESS';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL';
export const RESET_DELETE_REVIEW_SUCCESS = 'RESET_DELETE_REVIEW_SUCCESS';
export const REVIEW_EDITED_SUCCESS = 'REVIEW_EDITED_SUCCESS';
export const REVIEW_EDITED_FAIL = 'REVIEW_EDITED_FAIL';
export const RESET_REVIEW_EDITED_SUCCESS = 'RESET_REVIEW_EDITED_SUCCESS';

export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAIL = 'POST_EVENT_FAIL';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';
export const RESET_POST_EVENT_SUCCESS = 'RESET_POST_EVENT_SUCCESS';
export const RESET_DELETE_EVENT_SUCCESS = 'RESET_DELETE_EVENT_SUCCESS';
export const EVENT_EDITED_SUCCESS = 'EVENT_EDITED_SUCCESS';
export const EVENT_EDITED_FAIL = 'EVENT_EDITED_FAIL';
export const RESET_EVENT_EDITED_SUCCESS = 'RESET_EVENT_EDITED_SUCCESS';