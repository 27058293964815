import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	USER_LOADED_SUCCESS,
	USER_LOADED_FAIL,
	AUTHENTICATED_SUCCESS,
	AUTHENTICATED_FAIL,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_FAIL,
	PASSWORD_RESET_CONFIRM_SUCCESS,
	PASSWORD_RESET_CONFIRM_FAIL,
	SIGNUP_SUCCESS,
	SIGNUP_FAIL,
	ACTIVATION_SUCCESS,
	ACTIVATION_FAIL,
	GOOGLE_AUTH_SUCCESS,
	GOOGLE_AUTH_FAIL,
	LOGOUT
} from '../actions/types';

const initialState = {
	access: localStorage.getItem('access'),
	refresh: localStorage.getItem('refresh'),
	isAuthenticated: null,
	loginError: null,
	registrationError: null,
	user: null
};

export default function authReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case AUTHENTICATED_SUCCESS:
			return {
				...state,
				isAuthenticated: true
			}
		case LOGIN_SUCCESS:
		case GOOGLE_AUTH_SUCCESS:
			localStorage.setItem('access', payload.access);
			localStorage.setItem('refresh', payload.refresh);
			return {
				...state,
				isAuthenticated: true,
				access: payload.access,
				refresh: payload.refresh,
				loginError: null
			}
		case SIGNUP_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				registrationError: null
			}
		case USER_LOADED_SUCCESS:
			return {
				...state,
				user: payload
			}
		case AUTHENTICATED_FAIL:
			return {
				...state,
				isAuthenticated: false
			}
		case USER_LOADED_FAIL:
			return {
				...state,
				user: null
			}
		case LOGIN_FAIL:
			localStorage.removeItem('access');
			localStorage.removeItem('refresh');
			return {
				...state,
				access: null,
				refresh: null,
				isAuthenticated: false,
				loginError: payload,
				user: null
			}
		case SIGNUP_FAIL:
			localStorage.removeItem('access');
			localStorage.removeItem('refresh');
			return {
				...state,
				access: null,
				refresh: null,
				isAuthenticated: false,
				registrationError: payload,
				user: null
			}
		case LOGOUT:
		case GOOGLE_AUTH_FAIL:
			localStorage.removeItem('access');
			localStorage.removeItem('refresh');
			return {
				...state,
				access: null,
				refresh: null,
				isAuthenticated: false,
				loginError: null,
				registrationError: null,
				user: null
			}
		case PASSWORD_RESET_SUCCESS:
		case PASSWORD_RESET_FAIL:
		case PASSWORD_RESET_CONFIRM_SUCCESS:
		case PASSWORD_RESET_CONFIRM_FAIL:
		case ACTIVATION_SUCCESS:
		case ACTIVATION_FAIL:
			return {
				...state
			}
		default:
			return state
	}
};