import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPasswordConfirm } from '../../actions/auth';
import '../layout/layout.css';

import { Box, Button, Container, IconButton, InputAdornment, TextField, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


const ResetPasswordConfirm = ({ match, resetPasswordConfirm, setOpen }) => {
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [matchPassword, setMatchPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const { uid, token } = useParams();
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setOpen(false);
	})

	useEffect(() => {
		setErrorMessage('');
	}, [password, matchPassword]);

	const onSubmit = e => {
		e.preventDefault();

		if (password === matchPassword) {
			resetPasswordConfirm(uid, token, password, matchPassword);
			setSuccess(true);
		}
		else {
			setErrorMessage(t('errors.passwordMatch'));
		}
	};

	if (success) {
		return <Navigate to='/' />
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				pt: 12,
				pb: 3,
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<Box sx={{
					width: 350,
					boxShadow: 15,
					py: 5,
					px: 3,
					textAlign: 'center',
				}}>
					<Typography sx={{ fontWeight: 800 }} variant='h5'>
						{t('resetPasswordConfirm.title')}
					</Typography>
					<form className='form' onSubmit={onSubmit}>
						<TextField
							type={showPassword ? 'text' : 'password'}
							label={t('resetPasswordConfirm.textfield1')}
							defaultValue={password}
							onChange={(e) => setPassword(e.target.value)}
							minLength='8'
							InputProps={{
								endAdornment:
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPassword(!showPassword)}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
							}}
							required
							error={errorMessage}
							helperText={errorMessage}
							size='small'
						/>
						<TextField
							type={showPassword ? 'text' : 'password'}
							label={t('resetPasswordConfirm.textfield2')}
							defaultValue={matchPassword}
							onChange={(e) => setMatchPassword(e.target.value)}
							minLength='8'
							required
							error={errorMessage}
							helperText={errorMessage}
							size='small'
						/>
						<Button size='small' color='teal' variant='contained' type='submit'>
							{t('resetPasswordConfirm.button')}
						</Button>
					</form>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default connect(null, { resetPasswordConfirm })(ResetPasswordConfirm);

