import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from '../components/Navbar/Navbar';
import { connect } from 'react-redux';
import { checkAuthenticated, loadUser, googleAuthenticate } from '../actions/auth';
import './Layout.css';
import queryString from 'query-string';
import Home from '../containers/Home/Home';
import Activate from '../containers/Activate/Activate';
import Signup from '../containers/Signup/Signup';
import Login from '../containers/Login/Login';
import ResetPassword from '../containers/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../containers/ResetPasswordConfirm/ResetPasswordConfirm';
import Account from '../containers/Account/Account';
import Events from '../containers/Events/Events';
import MyEvents from "../containers/MyEvents/MyEvents";
import AboutUs from "../containers/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";
import { Box } from "@mui/material";

const Layout = ({ checkAuthenticated, loadUser, googleAuthenticate }) => {
	let location = useLocation();
	const showFooter = location.pathname !== '/events';
	const [open, setOpen] = useState(false);


	useEffect(() => {
		const values = queryString.parse(location.search),
			state = values.state ? values.state : null,
			code = values.code ? values.code : null;

		if (state && code) {
			googleAuthenticate(state, code);
		} else {
			checkAuthenticated();
			loadUser();
		}
	}, [checkAuthenticated, googleAuthenticate, loadUser, location]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
			<Navbar open={open} setOpen={setOpen} />
			
			<Box component="main" sx={{ flexGrow: 1 }}>
				<Routes>
					<Route path='/' element={<Home open={open} setOpen={setOpen} />} />
					<Route path='/about-us' element={<AboutUs open={open} setOpen={setOpen} />} />
					<Route path='/login' element={<Login open={open} setOpen={setOpen} />} />
					<Route path='/register' element={<Signup open={open} setOpen={setOpen} />} />
					<Route path='/reset-password' element={<ResetPassword open={open} setOpen={setOpen} />} />
					<Route path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm open={open} setOpen={setOpen} />} />
					<Route path='/activate/:uid/:token' element={<Activate open={open} setOpen={setOpen} />} />
					<Route path='/myaccount' element={<Account open={open} setOpen={setOpen} />} />
					<Route path='/myaccount/events' element={<MyEvents open={open} setOpen={setOpen} />} />
					<Route path='/events' element={<Events open={open} setOpen={setOpen} />} />
				</Routes>
			</Box>
			{showFooter && <Footer />}
		</Box>
	);
}

export default connect(null, { checkAuthenticated, loadUser, googleAuthenticate })(Layout);