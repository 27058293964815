import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18next
	.use(initReactI18next)
	.use(I18nextBrowserLanguageDetector)
	.init({
		debug: true,
		fallbackLng: 'en',
		resources: {
			en: {
				translation: {
					links: {
						about: 'About us',
						events: 'Events',
						login: 'Sign in',
						register: 'Sign up',
						myaccount: 'My account',
						myevents: 'My events',
						logout: 'Logout',
					},
					home: {
						header: 'Search for events with us',
						advantages: {
							title: 'Advantages of our site:',
							advantage1: 'Convenience',
							advantage2: 'Speed',
							advantage3: 'Easy-to-use',
						},
						start: 'Start your event',
					},
					account: {
						snackbar: 'Profile edited successfully!',
						modal: {
							title: 'Update Avatar',
							button: 'Upload file',
						},
						avatar: {
							description: 'Change Avatar',
							textfield1: 'Username',
							textfield2: 'Email',
							textfield3: 'Bio',
							button: 'Save',
						}
					},
					verify: {
						title: 'Verify your Account',
						button: 'Verify',
					},
					login: {
						title: 'Sign In',
						subtitle: 'Log into your Account',
						form: {
							textfield1: 'Email',
							textfield2: 'Password',
							button: 'Login',
						},
						additional: {
							register: {
								title: 'Don\'t have an account?',
								link: 'Sign Up',
							},
							resetPassword: {
								title: 'Forgot your Password?',
								link: 'Reset Password',
							},
							google: 'Continue with Google',
						}
					},
					resetPassword: {
						title: 'Request Password Reset',
						textfield1: 'Email',
						button: 'Reset Password',
					},
					resetPasswordConfirm: {
						title: 'Request Password Reset',
						textfield1: 'Password',
						textfield2: 'Confirm Password',
						button: 'Reset Password',
					},
					comment: {
						buttons: {
							author: {
								button1: 'Change',
								button2: 'Delete',
							},
						},
						snackbar: {
							delete: 'deleted',
							edit: 'edited',
							post: 'posted',
							success: 'Review {{value}} successfully!',
							fail: 'You can\'t post more than one review per event',
						},
						posted: 'posted',
						modal: {
							subtitle: 'Change your review.',
							textfield1: 'Change your comment',
							buttons: {
								save: 'Save',
								cancel: 'Cancel',
							}
						},
					},
					searchbar: {
						placeholder: 'Search...',
					},
					event: {
						modal: {
							title: {
								change: 'Change event',
								create: 'Create event',
							},
							datepicker: {
								start: 'Start date',
								end: 'End date',
							},
							image: 'Add event image',
							textfields: {
								textfield1: 'Title',
								textfield2: 'Description',
							},
							button: 'Save',
						},
						buttons: {
							change: 'Change',
							delete: 'Delete',
							additional: 'Learn More',
							reviews: 'Write a review',
						},
						reviews: 'reviews',
						datepicker: {
							start: 'Start',
							end: 'End',
						},
						accordion: {
							description: 'Description',
							reviews: 'Reviews',
						},
						snackbar: {
							delete: 'deleted',
							edit: 'edited',
							post: 'posted',
							success: 'Event {{value}} successfully!'
						},
					},
					review: {
						subtitle: 'Let us know what do you think about this event.',
						buttons: {
							cancel: 'Cancel',
							post: 'Post',
						},
						textfield1: 'Write your review',
					},
					events: {
						location: 'You are here',
						tabs: {
							active: 'Active',
							inactive: 'Inactive',
							all: 'All',
						},
					},
					errors: {
						authorization: 'Authorization error occured',
						login: 'Invalid email or password',
						passwordMatch: 'Passwords must match',
					},
					register: {
						title: 'Sign Up',
						subtitle: 'Create your Account',
						textfields: {
							textfield1: 'Username',
							textfield2: 'Email',
							textfield3: 'Password',
							textfield4: 'Confirm Password',
						},
						button: 'Register',
						additional: {
							login: {
								title: 'Already have an account?',
								link: 'Sign In',
							},
							google: 'Continue with Google',
						}
					},
					aboutUs: {
						'Eugene Deiak': 'Eugene Deiak',
						'Back-end developer': 'Back-end developer',
						'I am a web developer with nearly 1,5 year of experience. During this time, I have developed and managed projects for Mageap using Django and Django REST. I also worked as a problem solver where I solved algorithmic problems, and the last work was related to ML and data parsing':
							'I am a web developer with nearly 1,5 year of experience. During this time, I have developed and managed projects for Mageap using Django and Django REST. I also worked as a problem solver where I solved algorithmic problems, and the last work was related to ML and data parsing.',
						'Kyrylo Svir': 'Kyrylo Svir',
						'Front-end developer': 'Front-end developer',
						'I am a web developer with 0,5 year of experience, during which I did multiple pet-projects.':
						'I am a web developer with 0,5 year of experience, during which I did multiple pet-projects.',
					},
				}
			},
			uk: {
				translation: {
					links: {
						about: 'Про нас',
						events: 'Події',
						login: 'Увійти',
						register: 'Зареєструватись',
						myaccount: 'Мій профіль',
						myevents: 'Мої події',
						logout: 'Вийти',
					},
					home: {
						header: 'Шукай події з нами',
						advantages: {
							title: 'Переваги нашого сайту:',
							advantage1: 'Зручність',
							advantage2: 'Швидкість',
							advantage3: 'Простий у використанні',
						},
						start: 'Розпочніть свою подію',
					},
					account: {
						snackbar: 'Профіль успішно відредаговано!',
						modal: {
							title: 'Оновити аватар',
							button: 'Завантажити файл',
						},
						avatar: {
							description: 'Змінити аватар',
							textfield1: 'Ім\'я користувача',
							textfield2: 'Пошта',
							textfield3: 'Біо',
							button: 'Зберегти',
						},
					},
					verify: {
						title: 'Підтвердіть свій обліковий запис',
						button: 'Підтвердити',
					},
					login: {
						title: 'Увійти',
						subtitle: 'Увійдіть у Ваш аккаунт',
						form: {
							textfield1: 'Пошта',
							textfield2: 'Пароль',
							button: 'Увійти',
						},
						additional: {
							register: {
								title: 'Немає облікового запису?',
								link: 'Зареєструватись',
							},
							resetPassword: {
								title: 'Забули пароль?',
								link: 'Відновити пароль',
							},
							google: 'Продовжити з Google',
						}
					},
					resetPassword: {
						title: 'Запит на відновлення паролю',
						textfield1: 'Пошта',
						button: 'Відновити пароль',
					},
					resetPasswordConfirm: {
						title: 'Запит на відновлення паролю',
						textfield1: 'Пароль',
						textfield2: 'Підтвердіть пароль',
						button: 'Відновити пароль',
					},
					comment: {
						buttons: {
							author: {
								button1: 'Змінити',
								button2: 'Видалити',
							},
						},
						snackbar: {
							delete: 'видалено',
							edit: 'змінено',
							post: 'розміщено',
							success: 'Відгук {{value}} успішно!',
							fail: 'Ви не можете розміщати більше одного відгуку на подію',
						},
						posted: 'розміщено',
						modal: {
							subtitle: 'Змінити свій відгук.',
							textfield1: 'Змінити свій коментар',
							buttons: {
								save: 'Зберегти',
								cancel: 'Відмінити',
							}
						}
					},
					searchbar: {
						placeholder: 'Пошук...',
					},
					event: {
						modal: {
							title: {
								change: 'Редагувати подію',
								create: 'Створити подію',
							},
							datepicker: {
								start: 'Початкова дата',
								end: 'Кінцева дата',
							},
							image: 'Додати зображення',
							textfields: {
								textfield1: 'Назва',
								textfield2: 'Опис',
							},
							button: 'Зберегти',
						},
						buttons: {
							change: 'Змінити',
							delete: 'Видалити',
							additional: 'Дізнатись більше',
							reviews: 'Залишити відгук'
						},
						reviews: 'відгуків',
						datepicker: {
							start: 'Початок',
							end: 'Кінець',
						},
						accordion: {
							description: 'Опис',
							reviews: 'Відгуки',
						},
						snackbar: {
							delete: 'видалено',
							edit: 'змінено',
							post: 'розміщено',
							success: 'Подію {{value}} успішно!',
						},
					},
					review: {
						subtitle: 'Дайте нам знати, що ви думаєте про цю подію.',
						buttons: {
							cancel: 'Скасувати',
							post: 'Розмістити',
						},
						textfield1: 'Напишіть свій відгук',
					},
					events: {
						location: 'Ви тут',
						tabs: {
							active: 'Активні',
							inactive: 'Неактивні',
							all: 'Всі',
						},
					},
					errors: {
						authorization: 'Помилка авторизації',
						login: 'Невірна пошта або пароль',
						passwordMatch: 'Паролі мають співпадати',
					},
					register: {
						title: 'Зареєструватись',
						subtitle: 'Створіть свій аккаунт',
						textfields: {
							textfield1: 'Логін',
							textfield2: 'Пошта',
							textfield3: 'Пароль',
							textfield4: 'Підтвердіть пароль',
						},
						button: 'Зареєструватись',
						additional: {
							login: {
								title: 'Вже маєте аккаунт?',
								link: 'Увійти',
							},
							google: 'Продовжити з Google',
						}
					},
					aboutUs: {
						'Eugene Deiak': 'Євгеній Деяк',
						'Back-end developer': 'Back-end розробник',
						'I am a web developer with nearly 1,5 year of experience. During this time, I have developed and managed projects for Mageap using Django and Django REST. I also worked as a problem solver where I solved algorithmic problems, and the last work was related to ML and data parsing':
							'Я веб-розробник з майже 1,5-річним досвідом роботи. За цей час я розробив і керував проектами для Mageap, використовуючи Django і Django REST. Я також працював як вирішувач проблем, де я розв\'язував алгоритмічні завдання, і остання робота була пов\'язана з ML та аналізом даних.',
						'Kyrylo Svir': 'Кирило Свірь',
						'Front-end developer': 'Front-end розробник',
						'I am a web developer with 0,5 year of experience, during which I did multiple pet-projects.': 
						'Я веб-розробник з 0,5-річним досвідом роботи, протягом якого я розробив кілька pet-проектів.',
					},
				}
			}
		}
	})