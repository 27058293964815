import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { profileEdit, resetProfileEditedSuccess } from '../../actions/profile';
import './Account.css';

import { Box, Button, Typography, Modal, Container, IconButton, InputAdornment, TextField, ThemeProvider, Snackbar, Alert, FormControl } from '@mui/material';
import { modalStyle } from '../../MUI/styles';
import { defaultTheme } from '../../MUI/themes';
import { CloudUpload, Edit, EditOff, Lock } from '@mui/icons-material';
import { getUserInfo } from '../../actions/profile';
import ReactFileReader from "react-file-reader";
import { useTranslation } from 'react-i18next';




const Account = ({
	profileEdit,
	isAuthenticated,
	getUserInfo,
	userInfo,
	user,
	setOpen,
	profileEditedSuccess,
	resetProfileEditedSuccess
}) => {
	const { t } = useTranslation();
	const [imageUpload, setImageUpload] = useState(false);
	const [file, setFile] = useState(null);
	const [url, setUrl] = useState(null);
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [bio, setBio] = useState('');
	const [editBio, setEditBio] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	const onSubmit = e => {
		e.preventDefault();

		profileEdit(userInfo.id, bio, file ? file : null, username !== user?.username ? username : null);
	};

	const handleFiles = (files) => {
		setUrl(files.base64);
		setFile(files.fileList[0]);
		onClose();
	};

	useEffect(() => {
		if (userInfo && user) {
			setUrl(userInfo.avatar);
			setUsername(user.username);
			setEmail(user.email);
			setBio(userInfo.bio);
		}
	}, [userInfo, user])

	useEffect(() => {
		setOpen(false);
	})

	useEffect(() => {
		const fetchData = async () => {
			await getUserInfo();
		};

		fetchData();
	}, [isAuthenticated, getUserInfo, profileEditedSuccess])

	const onClose = () => {
		setImageUpload(false);
	};

	useEffect(() => {
		if (profileEditedSuccess) {
			setOpenSnackbar(true);
			resetProfileEditedSuccess();
		}
	}, [profileEditedSuccess, resetProfileEditedSuccess])

	if (isAuthenticated === false) {
		return <Navigate to='/login/' />
	}

	return (
		<Container sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			pt: 12.5,
			pb: 5,
		}}>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success"
					sx={{ width: '100%' }}
				>
					{t('account.snackbar')}
				</Alert>
			</Snackbar>
			<ThemeProvider theme={defaultTheme}>
				<Modal
					open={imageUpload}
					onClose={onClose}
				>
					<Box sx={modalStyle}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{t('account.modal.title')}
						</Typography>

						<ReactFileReader
							fileTypes={[".png", ".jpg"]}
							base64={true}
							handleFiles={handleFiles}
						>
							<Button component="label" variant="contained" color='teal' startIcon={<CloudUpload />}>
								{t('account.modal.button')}
							</Button>
						</ReactFileReader>
					</Box>
				</Modal>

				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					maxWidth: '100%',
				}}>
					<div className='Account-image'>
						<Box
							component='img'
							src={url}
							alt="Avatar"
							sx={{
								width: '100%',
								borderRadius: '50%',
								minWidth: { 
									xs: '200px',
									sm: '250px',
									md: '325px',
								},
								maxWidth: {
									xs: '200px',
									sm: '250px',
									md: '325px',
								},
								minHeight: {
									xs: '200px',
									sm: '250px',
									md: '325px',
								},
								maxHeight: {
									xs: '200px',
									sm: '250px',
									md: '325px',
								},
								transition: '1.5s ease',
							}}
						/>
						<div onClick={() => setImageUpload(true)} className='image-description-layer'>
							<Typography
								sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)' }}
								variant='h5'
								color='grey.800'
								className='image-description'
							>
								{t('account.avatar.description')}
							</Typography>
						</div>
					</div>
					<FormControl
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							pt: 2.5,
							gap: 2.5,
						}}
					>
						<TextField
							type='text'
							label={t('account.avatar.textfield1')}
							value={username}
							disabled
							onChange={(e) => setUsername(e.target.value)}
							sx={{
								width: {
									xs: '35ch',
									sm: '40ch',
									md: '50ch',
								},
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label="toggle username edit"
										edge="end"
									>
										<Lock />
									</IconButton>
								</InputAdornment>,
							}}
						/>
						<TextField
							type='email'
							label={t('account.avatar.textfield2')}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							disabled
							sx={{
								width: {
									xs: '35ch',
									sm: '40ch',
									md: '50ch',
								},
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label='locked email edit'
										edge='end'
									>
										<Lock />
									</IconButton>
								</InputAdornment>,
							}}
						/>
						<TextField
							label={t('account.avatar.textfield3')}
							multiline
							rows={5}
							value={bio ? bio : ' '}
							disabled={!editBio}
							onChange={(e) => setBio(e.target.value)}
							sx={{
								width: {
									xs: '35ch',
									sm: '40ch',
									md: '50ch',
								},
							}}
							InputProps={{
								endAdornment: <InputAdornment position='end' sx={{ mt: 1 }}>
									<IconButton
										aria-label='toggle bio edit'
										onClick={() => setEditBio(!editBio)}
										edge='end'
									>
										{editBio ? <EditOff /> : <Edit />}
									</IconButton>
								</InputAdornment>,
								sx: {
									alignItems: 'flex-start'
								}
							}}
						/>
						<Button
							variant='contained'
							color='teal'
							onClick={onSubmit}
						>
							{t('account.avatar.button')}
						</Button>
					</FormControl>
				</Box>
			</ThemeProvider>
		</Container>
	);
}

const mapStateToProps = state => ({
	user: state.auth.user,
	isAuthenticated: state.auth.isAuthenticated,
	userInfo: state.profile.userInfo,
	profileEditedSuccess: state.profile.profileEditedSuccess,
});

export default connect(mapStateToProps, { getUserInfo, profileEdit, resetProfileEditedSuccess })(Account);