import axios from 'axios';
import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	USER_LOADED_SUCCESS,
	USER_LOADED_FAIL,
	AUTHENTICATED_SUCCESS,
	AUTHENTICATED_FAIL,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_FAIL,
	PASSWORD_RESET_CONFIRM_SUCCESS,
	PASSWORD_RESET_CONFIRM_FAIL,
	SIGNUP_SUCCESS,
	SIGNUP_FAIL,
	ACTIVATION_SUCCESS,
	ACTIVATION_FAIL,
	GOOGLE_AUTH_SUCCESS,
	GOOGLE_AUTH_FAIL,
	LOGOUT
} from './types';
const API_URL = 'http://ec2-13-51-55-7.eu-north-1.compute.amazonaws.com/api';

export const loadUser = () => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`,
				'Accept': 'application/json'
			}
		};

		try {
			const response = await axios.get(`${API_URL}/auth/users/me/`, config);

			dispatch({
				type: USER_LOADED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: USER_LOADED_FAIL
			});
		}
	} else {
		dispatch({
			type: USER_LOADED_FAIL
		});
	}
};

export const googleAuthenticate = (state, code) => async dispatch => {
	if (state && code) {
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		};

		const details = {
			'state': state,
			'code': code
		};

		const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

		try {
			const response = await axios.post(`${API_URL}/auth/o/google-oauth2/?${formBody}`, config);
			console.log(response.data);
			dispatch({
				type: GOOGLE_AUTH_SUCCESS,
				payload: response.data
			});

			dispatch(loadUser());
		} catch (err) {
			dispatch({
				type: GOOGLE_AUTH_FAIL
			});
		}
	}
};

/* export const refreshToken = () => async dispatch => {
	const refreshToken = localStorage.getItem('refresh'); 
	if (refreshToken) {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const body = JSON.stringify({ refresh: refreshToken });

		try {
			const response = await axios.post(`${API_URL}/auth/jwt/refresh/`, body, config); 
			localStorage.setItem('access', response.data.access); 
			dispatch({
				type: REFRESH_TOKEN_SUCCESS,
				payload: response.data
			});
			dispatch(loadUser());
		} catch (err) {
			dispatch({
				type: REFRESH_TOKEN_FAIL
			});
			
		}
	} else {
		
	}
}; */

export const checkAuthenticated = () => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		};

		const body = JSON.stringify({ token: localStorage.getItem('access') });

		try {
			const response = await axios.post(`${API_URL}/auth/jwt/verify/`, body, config)

			if (response.data.code !== 'token_not_valid') {
				dispatch({
					type: AUTHENTICATED_SUCCESS
				});
			} else {
				dispatch({
					type: AUTHENTICATED_FAIL
				});
			}
		} catch (err) {
			dispatch({
				type: AUTHENTICATED_FAIL
			});
		}

	} else {
		dispatch({
			type: AUTHENTICATED_FAIL
		});
	}
};

export const login = (email, password) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({ email, password });

	try {
		const response = await axios.post(`${API_URL}/auth/jwt/create/`, body, config);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: response.data
		});

		dispatch(loadUser());
	} catch (err) {
		dispatch({
			type: LOGIN_FAIL,
			payload: err.response.data
		})
	}
};

export const signup = (username, email, password, re_password) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({ username, email, password, re_password });

	try {
		const response = await axios.post(`${API_URL}/auth/users/`, body, config);
		dispatch({
			type: SIGNUP_SUCCESS,
			payload: response.data
		});
	} catch (err) {
		dispatch({
			type: SIGNUP_FAIL,
			payload: err.response.data
		});
	}
};

export const verify = (uid, token) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({ uid, token });

	try {
		await axios.post(`${API_URL}/auth/users/activation/`, body, config);

		dispatch({
			type: ACTIVATION_SUCCESS,
		});
	} catch (err) {
		dispatch({
			type: ACTIVATION_FAIL
		});
	}
};

export const resetPassword = (email) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({ email });

	try {
		await axios.post(`${API_URL}/auth/users/reset_password/`, body, config);

		dispatch({
			type: PASSWORD_RESET_SUCCESS
		});
	} catch (err) {
		dispatch({
			type: PASSWORD_RESET_FAIL
		});
	}
};

export const resetPasswordConfirm = (uid, token, new_password, re_new_password) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const body = JSON.stringify({ uid, token, new_password, re_new_password });

	try {
		await axios.post(`${API_URL}/auth/users/reset_password_confirm/`, body, config);

		dispatch({
			type: PASSWORD_RESET_CONFIRM_SUCCESS
		});
	} catch (err) {
		dispatch({
			type: PASSWORD_RESET_CONFIRM_FAIL
		});
	}
};

export const logout = () => dispatch => {
	dispatch({
		type: LOGOUT
	});
};