import { styled } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 325;

export const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

export const modalStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: 3,
	alignItems: 'center',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: { xs: '325px', sm: '400px', md: '450px' },
	maxHeight: { xs: '675px', sm: '700px' },
	width: '100%',
	height: '100%',
	backgroundColor: 'background.paper',
	boxShadow: 20,
	p: 4,
};

export const advantageStyle = {
	display: 'flex',
	alignItems: 'center',
	gap: 0.5,
}

export const linkStyle = {
	ml: 0.5,
	color: '#008080',
	fontWeight: 600,
}