import React from 'react';
import { Box, IconButton, Menu, MenuItem, Skeleton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export default function EventItemSkeleton() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: {
					xs: 'column',
					sm: 'row',
				},
				width: '100%',
				bgcolor: 'white.dark',
				p: 2,
				gap: 2,
			}}
		>
			<Skeleton
				variant='rectangular'
				sx={{
					height: 200,
					width: {
						xs: 400,
						sm: 200,
					},
					maxWidth: '100%',
				}}
			/>
			<Box
				sx={{
					pt: 0.5,
					width: {
						xs: '70%',
						sm: '40%',
						md: '50%',
					},
				}}
			>
				<Skeleton variant='text' />
				<Skeleton variant='text' width="60%" />
			</Box>
			<IconButton
				sx={{
					position: 'absolute',
					right: 16,
				}}
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose}>
					Change
				</MenuItem>
				<MenuItem onClick={handleClose}>
					Delete
				</MenuItem>
			</Menu>
		</Box>
	);
}