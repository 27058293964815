import { createTheme, outlinedInputClasses, alpha, getContrastRatio } from "@mui/material";

const tealBase = '#008080';
const tealMain = alpha(tealBase, 0.7);
const darkBase = '#212121';
const darkMain = alpha(darkBase, 0.7);
const whiteBase = '#f5f5f5';
const whiteMain = alpha(whiteBase, 0.7);

export const defaultTheme = createTheme({
	palette: {
		teal: {
			main: tealMain,
			light: alpha(tealBase, 0.5),
			dark: alpha(tealBase, 0.9),
			contrastText: getContrastRatio(tealMain, '#fff') > 4.5 ? '#fff' : '#111',
		},
		white: {
			main: whiteMain,
			light: alpha(whiteBase, 0.5),
			dark: alpha(whiteBase, 0.9),
			contrastText: getContrastRatio(whiteMain, '#fff') > 4.5 ? '#fff' : '#111',
		},
		dark: {
			main: darkMain,
			light: alpha(darkBase, 0.5),
			dark: alpha(darkBase, 0.9),
			contrastText: getContrastRatio(darkMain, '#fff') > 4.5 ? '#fff' : '#111',
		}
	},
	components: {
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: 'teal',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: '#757575',
					'&.Mui-selected': {
						color: 'teal',
					},
				},
			},
		},
		MuiTypography: {
			variants: [
				{
					props: {
						variant: 'body3',
					},
					style: {
						fontSize: 10,
					}
				}
			]
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'--TextField-brandBorderColor': '#E0E3E7',
					'--TextField-brandBorderHoverColor': '#006666',
					'--TextField-brandBorderFocusedColor': '#008080',
					'& label.Mui-focused': {
						color: 'var(--TextField-brandBorderFocusedColor)',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: 'var(--TextField-brandBorderColor)',
				},
				root: {
					[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: 'var(--TextField-brandBorderHoverColor)',
					},
					[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
						borderColor: 'var(--TextField-brandBorderFocusedColor)',
					},
				},
			},
		},
	}
});