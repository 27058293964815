import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Box, IconButton, Typography, Button, ThemeProvider, Container, Divider, Stack } from "@mui/material";
import SvgIcon from '@mui/material/SvgIcon';
import { defaultTheme } from '../../MUI/themes';
import '../../containers/layout/layout.css';
import { GitHub, LinkedIn, Telegram } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Footer = ({ isAuthenticated }) => {
	const { t } = useTranslation();

	const guestLinks = () => {
		return (
			<>
				<Button
					component={Link}
					to='/login'
					sx={{
						color: 'inherit',
						fontSize: {
							xs: '.7rem',
							sm: '.8rem',
							md: '.9rem',
						},
					}}
				>
					{t('links.login')}
				</Button>
				<Button
					component={Link}
					to='register'
					sx={{
						color: 'inherit',
						fontSize: {
							xs: '.7rem',
							sm: '.8rem',
							md: '.9rem',
						},
					}}
				>
					{t('links.register')}
				</Button>
			</>
		);
	};

	const authLinks = () => {
		return (
			<>
				<Button
					component={Link}
					to='/myaccount'
					sx={{
						color: 'inherit',
						fontSize: {
							xs: '.7rem',
							sm: '.8rem',
							md: '.9rem',
						},
					}}
				>
					{t('links.myaccount')}
				</Button>
				<Button
					component={Link}
					to='/myaccount/events'
					sx={{
						color: 'inherit',
						fontSize: {
							xs: '.7rem',
							sm: '.8rem',
							md: '.9rem',
						},
					}}
				>
					{t('links.myevents')}
				</Button>
			</>
		);
	};

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{
					boxShadow: 20,
					py: 2,
					px: 3,
					mt: { xs: 2.5, sm: 4, md: 7.5 },
				}}>
					<Container maxWidth="xl">
						<Box sx={{
							display: 'flex',
							flexDirection: { xs: 'column', md: 'row' },
							justifyContent: 'center',
							gap: { xs: 0, md: 5, lg: 10 },
						}}>
							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
								<Box
									component={Link}
									to='/'
									sx={{
										display: 'flex',
										color: 'inherit',
										textDecoration: 'none',
									}}
								>
									<SvgIcon sx={{ mr: 0.5, mb: 1 }} >
										{

											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 57" fill="none">
												<g clipPath="url(#clip0_32_28)">
													<path d="M0 47.7234V9.24961C0 6.75278 0.797292 4.58697 2.39188 2.75218C3.98646 0.917395 5.98896 0 8.39938 0H47.17C49.7658 0 51.926 0.709326 53.6503 2.12798C55.3747 3.54663 56.2369 5.56111 56.2369 8.17143V11.5194C53.4927 10.9898 51.4346 10.6115 50.0625 10.3845C48.0971 10.1197 47.0958 9.9873 47.0588 9.9873C46.6879 9.87381 46.1409 9.68466 45.4178 9.41984C44.6947 9.15503 44.0643 8.95642 43.5266 8.82401C42.9889 8.6916 42.3863 8.6254 41.7188 8.6254C39.4196 8.6254 36.9721 9.51442 34.3763 11.2925C33.1154 11.406 31.3447 11.5384 29.0641 11.6897C26.7834 11.841 24.7068 12.0112 22.8341 12.2004C20.9614 12.3896 19.1906 12.6544 17.5219 12.9948H17.4106L17.355 13.0516H17.1325L17.0769 13.1083H17.0213C14.3513 13.638 12.7381 14.1298 12.1819 14.5837C11.5144 15.1512 11.1435 16.4942 11.0694 18.6127V27.9758L10.9025 28.9972L40.1613 29.7349L44.6113 28.2028C42.9796 31.1914 41.6817 33.6882 40.7175 35.6933L34.8213 38.3603L12.2375 38.7576C12.1263 38.871 11.9408 38.9372 11.6813 38.9562C11.4217 38.9751 11.2177 39.0602 11.0694 39.2115C11.2177 40.1951 11.3475 40.9328 11.4588 41.4246C11.9038 43.3918 13.9804 44.5078 17.6888 44.7726C25.3279 45.2644 31.5394 45.5103 36.3231 45.5103C37.7323 45.5103 39.9573 45.132 42.9981 44.3754C46.039 43.6188 48.3938 43.2405 50.0625 43.2405C52.4729 43.2405 54.5311 43.789 56.2369 44.8861V47.9504C56.2369 53.8142 53.3815 56.7461 47.6706 56.7461H7.45375C5.19167 56.7461 3.38386 55.8381 2.03031 54.0222C0.676771 52.2064 0 50.1068 0 47.7234ZM38.3813 16.7401C38.3813 16.2105 38.5667 15.747 38.9375 15.3498C39.3083 14.9526 39.7533 14.754 40.2725 14.754C40.7917 14.754 41.2459 14.9526 41.6353 15.3498C42.0247 15.747 42.2194 16.2105 42.2194 16.7401C42.2194 17.2697 42.0247 17.7237 41.6353 18.102C41.2459 18.4803 40.7917 18.6695 40.2725 18.6695C39.7533 18.6695 39.3083 18.4803 38.9375 18.102C38.5667 17.7237 38.3813 17.2697 38.3813 16.7401Z" fill="#212121" />
												</g>
												<defs>
													<clipPath id="clip0_32_28">
														<rect width="55.625" height="56.7461" fill="#212121" />
													</clipPath>
												</defs>
											</svg>

										}
									</SvgIcon>
									<Typography
										variant="h6"
										noWrap
										sx={{
											mr: 2,
											fontFamily: 'monospace',
											fontWeight: 700,
											letterSpacing: '.3rem',
										}}
									>
										VENT MAP
									</Typography>
								</Box>
								<Stack direction="row" spacing={1}>
									<IconButton color="primary">
										<LinkedIn />
									</IconButton>
									<IconButton color='dark'>
										<GitHub />
									</IconButton>
									<IconButton color="primary">
										<Telegram />
									</IconButton>
								</Stack>
							</Box>

							<Box sx={{
								display: 'flex',
								flexDirection: { xs: 'column', sm: 'row' },
								alignItems: 'center',
								justifyContent: 'center',
								gap: {
									xs: 0,
									md: 2.5,
									lg: 5
								},
							}}>
								<Box textAlign='center'>
									<Button
										component={Link}
										to='/about-us'
										sx={{
											color: 'inherit',
											fontSize: {
												xs: '.7rem',
												sm: '.8rem',
												md: '.9rem',
											},
										}}
									>
										{t('links.about')}
									</Button>
									<Button
										component={Link}
										to='/events'
										sx={{
											color: 'inherit',
											fontSize: {
												xs: '.7rem',
												sm: '.8rem',
												md: '.9rem',
											},
										}}
									>
										{t('links.events')}
									</Button>
								</Box>
								<Box textAlign='center'>
									{isAuthenticated ? authLinks() : guestLinks()}
								</Box>
							</Box>
						</Box>


						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: .5
						}}>
							<Divider
								sx={{
									display: 'flex',
									justifySelf: 'center',
									width: '10%',
								}}
							/>
							<Typography
								variant='body1'
								color='text.secondary'
							>
								© Event Map
							</Typography>
						</Box>
					</Container>
				</Box >
			</ThemeProvider >
		</>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(Footer);