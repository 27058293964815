import {
	PROFILE_EDITED_SUCCESS,
	PROFILE_EDITED_FAIL,
	RESET_PROFILE_EDITED_SUCCESS,

	GET_USER_INFO_SUCCESS,
	GET_USER_INFO_FAIL
} from '../actions/types';

const initialState = {
	userInfo: null,
	profileEditedSuccess: null,
	error: null,
};

export default function profileReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case PROFILE_EDITED_SUCCESS:
			return {
				...state,
				profileEditedSuccess: true,
			}
		case PROFILE_EDITED_FAIL:
		case RESET_PROFILE_EDITED_SUCCESS:
			return {
				...state,
				profileEditedSuccess: false,
			}
		case GET_USER_INFO_FAIL:
			return {
				...state,
				error: payload,
			}
		case GET_USER_INFO_SUCCESS:
			return {
				...state,
				userInfo: payload,
			}
		default:
			return state;
	}
}