import {
	EVENTS_LOADED_SUCCESS,
	EVENTS_LOADED_FAIL,
	MY_EVENTS_LOADED_SUCCESS,
	MY_EVENTS_LOADED_FAIL,
	POST_EVENT_SUCCESS,
	POST_EVENT_FAIL,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAIL,
	RESET_POST_EVENT_SUCCESS,
	RESET_DELETE_EVENT_SUCCESS,
	EVENT_EDITED_SUCCESS,
	EVENT_EDITED_FAIL,
	RESET_EVENT_EDITED_SUCCESS,

	POST_REVIEW_SUCCESS,
	POST_REVIEW_FAIL,
	LOAD_REVIEWS_SUCCESS,
	LOAD_REVIEWS_FAIL,
	DELETE_REVIEW_SUCCESS,
	DELETE_REVIEW_FAIL,
	RESET_POST_REVIEW_SUCCESS,
	RESET_DELETE_REVIEW_SUCCESS,
	REVIEW_EDITED_SUCCESS,
	REVIEW_EDITED_FAIL,
	RESET_REVIEW_EDITED_SUCCESS,
} from '../actions/types';

const initialState = {
	events: null,
	myEvents: null,
	postReviewSuccess: null,
	deleteReviewSuccess: null,
	editReviewSuccess: null,
	postEventSuccess: null,
	deleteEventSuccess: null,
	editEventSuccess: null,
	reviews: null,
};

export default function mapReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case EVENTS_LOADED_SUCCESS:
			return {
				...state,
				events: payload,
			}
		case MY_EVENTS_LOADED_SUCCESS:
			return {
				...state,
				myEvents: payload,
			}
		case POST_REVIEW_SUCCESS:
			return {
				...state,
				postReviewSuccess: true,
			}
		case DELETE_REVIEW_SUCCESS:
			return {
				...state,
				deleteReviewSuccess: true,
			}
		case REVIEW_EDITED_SUCCESS:
			return {
				...state,
				editReviewSuccess: true,
			}
		case LOAD_REVIEWS_SUCCESS:
			return {
				...state,
				reviews: payload,
			}
		case POST_EVENT_SUCCESS:
			return {
				...state,
				postEventSuccess: true,
			}
		case LOAD_REVIEWS_FAIL:
			return {
				...state,
				reviews: null,
			}
		case EVENTS_LOADED_FAIL:
			return {
				...state,
				events: null,
			}
		case MY_EVENTS_LOADED_FAIL:
			return {
				...state,
				myEvents: null,
			}
		case POST_REVIEW_FAIL:
			return {
				...state,
				postReviewSuccess: false,
			}
		case RESET_POST_REVIEW_SUCCESS:
			return {
				...state,
				postReviewSuccess: null,
			}
		case POST_EVENT_FAIL:
		case RESET_POST_EVENT_SUCCESS:
			return {
				...state,
				postEventSuccess: false,
			}
		case DELETE_REVIEW_FAIL:
		case RESET_DELETE_REVIEW_SUCCESS:
			return {
				...state,
				deleteReviewSuccess: false,
			}
		case REVIEW_EDITED_FAIL:
		case RESET_REVIEW_EDITED_SUCCESS:
			return {
				...state,
				editReviewSuccess: false,
			}
		case DELETE_EVENT_SUCCESS:
			return {
				...state,
				deleteEventSuccess: true,
			}
		case DELETE_EVENT_FAIL:
		case RESET_DELETE_EVENT_SUCCESS:
			return {
				...state,
				deleteEventSuccess: false,
			}
		case EVENT_EDITED_SUCCESS:
			return {
				...state,
				editEventSuccess: true,
			}
		case EVENT_EDITED_FAIL:
		case RESET_EVENT_EDITED_SUCCESS:
			return {
				...state,
				editEventSuccess: false,
			}
		default:
			return state;
	}
}