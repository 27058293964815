import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { Box, IconButton, Menu, Toolbar, Typography, Button, ThemeProvider, Container, MenuItem, Tooltip, Avatar, styled, Switch } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import { defaultTheme } from '../../MUI/themes';

import '../../containers/layout/layout.css';
import { AppBar } from "../../MUI/styles";
import { getUserInfo } from "../../actions/profile";
import { useTranslation } from "react-i18next";

const LanguageSwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 0,
		transform: 'translateX(6px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(22px)',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cpath%20fill%3D%22%23005BBB%22%20d%3D%22M32%205H4a4%204%200%200%200-4%204v9h36V9a4%204%200%200%200-4-4z%22%2F%3E%3Cpath%20fill%3D%22%23FFD500%22%20d%3D%22M36%2027a4%204%200%200%201-4%204H4a4%204%200%200%201-4-4v-9h36v9z%22%2F%3E%3C%2Fsvg%3E')`,
				backgroundSize: '20px 20px',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			},
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
			},
		},
	},
	'& .MuiSwitch-thumb': {
		backgroundColor: theme.palette.mode === 'dark' ? '#d0d0d0' : '#f5f5f5',
		width: 32,
		height: 32,
		'&::before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url('data:image/svg+xml;utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 fill=%27%23FFF%27 viewBox=%270 0 1235 650%27%3E%3Cpath d=%27M0 0h1235v650H0%27/%3E%3Cpath stroke=%27%23b22234%27 stroke-dasharray=%2750%27 stroke-width=%272470%27 d=%27M0 0v651%27/%3E%3Cpath fill=%27%233c3b6e%27 d=%27M0 0h494v350H0%27/%3E%3Cg id=%27e%27%3E%3Cg id=%27d%27%3E%3Cg id=%27f%27%3E%3Cg id=%27c%27%3E%3Cg id=%27b%27%3E%3Cpath id=%27a%27 d=%27m30 50.6 12-36 12 36-30.8-22H61%27/%3E%3Cuse xlink:href=%27%23a%27 x=%2782%27/%3E%3C/g%3E%3Cuse xlink:href=%27%23b%27 x=%27164%27/%3E%3Cuse xlink:href=%27%23a%27 x=%27328%27/%3E%3C/g%3E%3Cuse xlink:href=%27%23a%27 x=%27410%27/%3E%3C/g%3E%3Cuse xlink:href=%27%23c%27 x=%2741%27 y=%2735%27/%3E%3C/g%3E%3Cuse xlink:href=%27%23d%27 y=%2770%27/%3E%3C/g%3E%3Cuse xlink:href=%27%23e%27 y=%27140%27/%3E%3Cuse xlink:href=%27%23f%27 y=%27280%27/%3E%3C/svg%3E')`,
			backgroundSize: '20px 20px',
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
		borderRadius: 20 / 2,
	},
}));

const Navbar = ({ open, logout, isAuthenticated, getUserInfo, userInfo }) => {
	const { t, i18n } = useTranslation();
	const [checked, setChecked] = useState(i18n.language === 'uk');
	const [redirect, setRedirect] = useState(false);
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleSwitch = (event) => {
		setChecked(event.target.checked);
		i18n.changeLanguage(event.target.checked === true ? 'uk' : 'en');
		console.log(i18n.language);
	};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = (e) => {
		setAnchorElUser(null);
	};

	const logoutUser = () => {
		logout();
		setRedirect(true);
	};

	const guestLinks = () => {
		return (
			<Box sx={{
				display: 'flex',
				gap: 2
			}}>
				<Button
					component={Link}
					to='/login'
					variant='contained'
					color='teal'
				>
					{t('links.login')}
				</Button>
				<Button
					component={Link}
					to='/register'
					variant='outlined'
					color='teal'
					sx={{
						display: { xs: 'none', md: 'flex' },
					}}
				>
					{t('links.register')}
				</Button>
			</Box>
		);
	};

	const authLinks = () => {
		return (
			<Box sx={{ flexGrow: 0 }}>
				<Tooltip title="Open settings">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar sx={{ boxShadow: 2 }} alt='User' src={userInfo?.avatar} />
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: '45px' }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					<MenuItem component={Link} to='/myaccount/' onClick={handleCloseUserMenu}>
						{t('links.myaccount')}
					</MenuItem>
					<MenuItem component={Link} to='/myaccount/events/' onClick={handleCloseUserMenu}>
						{t('links.myevents')}
					</MenuItem>
					<MenuItem component={Link} onClick={() => { handleCloseUserMenu(); logoutUser(); }}>
						{t('links.logout')}
					</MenuItem>
				</Menu>
			</Box>
		);
	};

	useEffect(() => {
		if (isAuthenticated) {
			const fetchData = async () => {
				await getUserInfo();
			};

			fetchData();
		}
	}, [isAuthenticated, getUserInfo])

	if (redirect) {
		setRedirect(false);
		return <Navigate to='/' />
	}

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{ flexGrow: 1 }}>
					<AppBar color='inherit' position="fixed" open={open}>
						<Container maxWidth="xl">
							<Toolbar disableGutters>
								<Box
									component={Link}
									to='/'
									sx={{
										display: 'flex',
										color: 'inherit',
										textDecoration: 'none',
									}}
								>
									<SvgIcon
										sx={{
											display: {
												xs: 'none',
												md: 'flex'
											},
											mr: 0.5,
											mb: 1
										}} >
										{

											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 57" fill="none">
												<g clipPath="url(#clip0_32_28)">
													<path d="M0 47.7234V9.24961C0 6.75278 0.797292 4.58697 2.39188 2.75218C3.98646 0.917395 5.98896 0 8.39938 0H47.17C49.7658 0 51.926 0.709326 53.6503 2.12798C55.3747 3.54663 56.2369 5.56111 56.2369 8.17143V11.5194C53.4927 10.9898 51.4346 10.6115 50.0625 10.3845C48.0971 10.1197 47.0958 9.9873 47.0588 9.9873C46.6879 9.87381 46.1409 9.68466 45.4178 9.41984C44.6947 9.15503 44.0643 8.95642 43.5266 8.82401C42.9889 8.6916 42.3863 8.6254 41.7188 8.6254C39.4196 8.6254 36.9721 9.51442 34.3763 11.2925C33.1154 11.406 31.3447 11.5384 29.0641 11.6897C26.7834 11.841 24.7068 12.0112 22.8341 12.2004C20.9614 12.3896 19.1906 12.6544 17.5219 12.9948H17.4106L17.355 13.0516H17.1325L17.0769 13.1083H17.0213C14.3513 13.638 12.7381 14.1298 12.1819 14.5837C11.5144 15.1512 11.1435 16.4942 11.0694 18.6127V27.9758L10.9025 28.9972L40.1613 29.7349L44.6113 28.2028C42.9796 31.1914 41.6817 33.6882 40.7175 35.6933L34.8213 38.3603L12.2375 38.7576C12.1263 38.871 11.9408 38.9372 11.6813 38.9562C11.4217 38.9751 11.2177 39.0602 11.0694 39.2115C11.2177 40.1951 11.3475 40.9328 11.4588 41.4246C11.9038 43.3918 13.9804 44.5078 17.6888 44.7726C25.3279 45.2644 31.5394 45.5103 36.3231 45.5103C37.7323 45.5103 39.9573 45.132 42.9981 44.3754C46.039 43.6188 48.3938 43.2405 50.0625 43.2405C52.4729 43.2405 54.5311 43.789 56.2369 44.8861V47.9504C56.2369 53.8142 53.3815 56.7461 47.6706 56.7461H7.45375C5.19167 56.7461 3.38386 55.8381 2.03031 54.0222C0.676771 52.2064 0 50.1068 0 47.7234ZM38.3813 16.7401C38.3813 16.2105 38.5667 15.747 38.9375 15.3498C39.3083 14.9526 39.7533 14.754 40.2725 14.754C40.7917 14.754 41.2459 14.9526 41.6353 15.3498C42.0247 15.747 42.2194 16.2105 42.2194 16.7401C42.2194 17.2697 42.0247 17.7237 41.6353 18.102C41.2459 18.4803 40.7917 18.6695 40.2725 18.6695C39.7533 18.6695 39.3083 18.4803 38.9375 18.102C38.5667 17.7237 38.3813 17.2697 38.3813 16.7401Z" fill="#212121" />
												</g>
												<defs>
													<clipPath id="clip0_32_28">
														<rect width="55.625" height="56.7461" fill="#212121" />
													</clipPath>
												</defs>
											</svg>

										}
									</SvgIcon>
									<Typography
										variant="h6"
										noWrap
										component={Link}
										to='/'
										sx={{
											mr: 2,
											display: { xs: 'none', md: 'flex' },
											fontFamily: 'monospace',
											fontWeight: 700,
											letterSpacing: '.3rem',
											textDecoration: 'none',
											color: 'inherit',
										}}
									>
										VENT MAP
									</Typography>
								</Box>

								<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
									<IconButton
										size="large"
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={handleOpenNavMenu}
										color="inherit"
									>
										<MenuIcon />
									</IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={anchorElNav}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										open={Boolean(anchorElNav)}
										onClose={handleCloseNavMenu}
										sx={{
											display: { xs: 'block', md: 'none' },
										}}
									>
										<MenuItem component={Link} to='/about-us' onClick={handleCloseNavMenu}>
											{t('links.about')}
										</MenuItem>
										<MenuItem component={Link} to='/events' onClick={handleCloseNavMenu}>
											{t('links.events')}
										</MenuItem>
									</Menu>
								</Box>

								<Box
									component={Link}
									to='/'
									sx={{
										flexGrow: 1,
										display: { xs: 'flex', md: 'none' },
										justifyContent: 'center',
										color: 'inherit',
										textDecoration: 'none',
									}}
								>
									<SvgIcon
										sx={{
											mr: 0.5,
											mb: 1
										}}
									>
										{

											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 57" fill="none">
												<g clipPath="url(#clip0_32_28)">
													<path d="M0 47.7234V9.24961C0 6.75278 0.797292 4.58697 2.39188 2.75218C3.98646 0.917395 5.98896 0 8.39938 0H47.17C49.7658 0 51.926 0.709326 53.6503 2.12798C55.3747 3.54663 56.2369 5.56111 56.2369 8.17143V11.5194C53.4927 10.9898 51.4346 10.6115 50.0625 10.3845C48.0971 10.1197 47.0958 9.9873 47.0588 9.9873C46.6879 9.87381 46.1409 9.68466 45.4178 9.41984C44.6947 9.15503 44.0643 8.95642 43.5266 8.82401C42.9889 8.6916 42.3863 8.6254 41.7188 8.6254C39.4196 8.6254 36.9721 9.51442 34.3763 11.2925C33.1154 11.406 31.3447 11.5384 29.0641 11.6897C26.7834 11.841 24.7068 12.0112 22.8341 12.2004C20.9614 12.3896 19.1906 12.6544 17.5219 12.9948H17.4106L17.355 13.0516H17.1325L17.0769 13.1083H17.0213C14.3513 13.638 12.7381 14.1298 12.1819 14.5837C11.5144 15.1512 11.1435 16.4942 11.0694 18.6127V27.9758L10.9025 28.9972L40.1613 29.7349L44.6113 28.2028C42.9796 31.1914 41.6817 33.6882 40.7175 35.6933L34.8213 38.3603L12.2375 38.7576C12.1263 38.871 11.9408 38.9372 11.6813 38.9562C11.4217 38.9751 11.2177 39.0602 11.0694 39.2115C11.2177 40.1951 11.3475 40.9328 11.4588 41.4246C11.9038 43.3918 13.9804 44.5078 17.6888 44.7726C25.3279 45.2644 31.5394 45.5103 36.3231 45.5103C37.7323 45.5103 39.9573 45.132 42.9981 44.3754C46.039 43.6188 48.3938 43.2405 50.0625 43.2405C52.4729 43.2405 54.5311 43.789 56.2369 44.8861V47.9504C56.2369 53.8142 53.3815 56.7461 47.6706 56.7461H7.45375C5.19167 56.7461 3.38386 55.8381 2.03031 54.0222C0.676771 52.2064 0 50.1068 0 47.7234ZM38.3813 16.7401C38.3813 16.2105 38.5667 15.747 38.9375 15.3498C39.3083 14.9526 39.7533 14.754 40.2725 14.754C40.7917 14.754 41.2459 14.9526 41.6353 15.3498C42.0247 15.747 42.2194 16.2105 42.2194 16.7401C42.2194 17.2697 42.0247 17.7237 41.6353 18.102C41.2459 18.4803 40.7917 18.6695 40.2725 18.6695C39.7533 18.6695 39.3083 18.4803 38.9375 18.102C38.5667 17.7237 38.3813 17.2697 38.3813 16.7401Z" fill="#212121" />
												</g>
												<defs>
													<clipPath id="clip0_32_28">
														<rect width="55.625" height="56.7461" fill="#212121" />
													</clipPath>
												</defs>
											</svg>

										}
									</SvgIcon>
									<Typography
										variant="h5"
										noWrap
										sx={{
											display: { xs: 'none', sm: 'flex', },
											mr: 2,
											fontFamily: 'monospace',
											fontWeight: 700,
											letterSpacing: '.3rem',
										}}
									>
										VENT MAP
									</Typography>
								</Box>
								<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
									<Button
										onClick={handleCloseNavMenu}
										component={Link}
										to='/about-us'
										sx={{ my: 2, color: 'inherit', display: 'block' }}
									>
										{t('links.about')}
									</Button>
									<Button
										onClick={handleCloseNavMenu}
										component={Link}
										to='/events'
										sx={{ my: 2, color: 'inherit', display: 'block' }}
									>
										{t('links.events')}
									</Button>
								</Box>
								<LanguageSwitch
									checked={checked}
									sx={{ m: 1 }}
									onChange={handleSwitch}
								/>
								{isAuthenticated ? authLinks() : guestLinks()}
							</Toolbar>
						</Container>
					</AppBar>
				</Box>
			</ThemeProvider>
		</>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	userInfo: state.profile.userInfo
});

export default connect(mapStateToProps, { logout, getUserInfo })(Navbar);