import React from 'react';
import { Box, Stack, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { useTranslation } from 'react-i18next';

const DeveloperInfo = ({ developer, reverse }) => {
	const { t } = useTranslation();
	return (
		<ThemeProvider theme={defaultTheme}>
			<Box sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					md: reverse ? 'row-reverse' : 'row'
				},
				justifyContent: 'center',
				alignItems: 'center',
				gap: {
					xs: 2.5,
					sm: 5,
					md: 10,
					lg: 15,
				},
			}}>
				<Box
					component='img'
					alt='Developer'
					src={developer.img}
					sx={{
						height: {
							xs: 300,
							sm: 375,
							md: 325,
							lg: 350,
						},
						minWidth: {
							xs: 300,
							sm: 375,
							md: 325,
							lg: 350,
						},
						maxWidth: {
							xs: 300,
							sm: 375,
							md: 325,
							lg: 350,
						},
						width: '100%',
						minHeight: '100%',
						borderRadius: '50%',
						boxShadow: 9
					}}
				/>
				<Stack
					spacing={1}
					sx={{
						textAlign: {
							xs: 'center',
							md: 'start',
						}
					}}
				>
					<Typography
						sx={{
							fontWeight: 425,
							fontSize: {
								xs: '2.25rem',
								sm: '2.5rem',
								md: '3rem',
							},
							backgroundImage: `linear-gradient(45deg, #008080, #05100e)`,
							backgroundSize: "100%",
							backgroundRepeat: "repeat",
							backgroundClip: "text",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						}}
					>
						{t(`aboutUs.${developer.name}`)}
					</Typography>
					<Typography
						variant='h4'
						sx={{
							fontWeight: 415,
							fontSize: {
								xs: '1.4rem',
								sm: '1.85rem',
								md: '2.15rem',
							},
						}}
					>
						{t(`aboutUs.${developer.specialization}`)}
					</Typography>
					<Typography
						color='text.secondary'
						variant='h6'
						sx={{
							pt: 2,
							fontSize: {
								xs: '.85rem',
								sm: '1rem',
								md: '1.15rem',
							},
						}}
					>
						{t(`aboutUs.${developer.bio}`)}
					</Typography>
				</Stack>
			</Box>
		</ThemeProvider>
	);
}

export default DeveloperInfo;