import { Box, Button, IconButton, Modal, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { modalStyle } from '../../MUI/styles';
import { defaultTheme } from '../../MUI/themes';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactFileReader from "react-file-reader";
import './EventModal.css';
import { connect } from 'react-redux';
import { postEvent, editEvent } from '../../actions/map';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

const EventModal = ({
	modalOpen,
	setModalOpen,
	positionX,
	positionY,
	postEvent,

	eid,
	eventTitle,
	eventImage,
	eventDescription,
	eventStart,
	eventEnd,
	isChanged,
	editEvent,
}) => {
	const { t } = useTranslation();
	const [file, setFile] = useState(null);
	const [url, setUrl] = useState(eventImage);
	const [title, setTitle] = useState(eventTitle);
	const [startDate, setStartDate] = useState(eventStart ? dayjs(eventStart) : dayjs());
	const [endDate, setEndDate] = useState(eventEnd ? dayjs(eventEnd) : dayjs());
	const [description, setDescription] = useState(eventDescription);

	const handleFiles = (files) => {
		setUrl(files.base64);
		setFile(files.fileList[0]);
	};

	const onStartDateChange = (newValue) => {
		setStartDate(newValue);
		setEndDate(newValue);
	}

	const onEndDateChange = (newValue) => {
		setEndDate(newValue);
		if (newValue < startDate) {
			setStartDate(newValue);
		}
	}

	const onSubmitEdit = (e) => {
		e.preventDefault();
		editEvent(eid, title, description, startDate.toISOString(), endDate.toISOString(), file);
		setModalOpen(false);
	}
	const onSubmitPost = (e) => {
		e.preventDefault();
		postEvent(title, description, startDate.toISOString(), endDate.toISOString(), file, positionX, positionY);
		setModalOpen(false);
	};

	return (
		<ThemeProvider theme={defaultTheme}>
			<Modal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			>
				<Box sx={modalStyle}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{isChanged ? t('event.modal.title.change') : t('event.modal.title.create')}
					</Typography>
					<IconButton
						sx={{
							position: 'absolute',
							right: 16,
						}}
						onClick={(e) => setModalOpen(false)}
					>
						<Close />
					</IconButton>
					<form onSubmit={isChanged ? onSubmitEdit : onSubmitPost}>
						<Box sx={{
							mr: 5.25
						}}>
							<ReactFileReader
								fileTypes={[".png", ".jpg"]}
								base64={true}
								handleFiles={handleFiles}
							>
								<div className='Event-image'>

									<img src={url} alt=" " />
									<div className='image-description-layer'>
										<Typography
											sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)' }}
											variant='h5'
											color='grey.800'
											className='image-description'
										>
											{t('event.modal.image')}
										</Typography>
									</div>
								</div>
							</ReactFileReader>
						</Box>
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							py: 3
						}}>
							<TextField
								type='text'
								label={t('event.modal.textfields.textfield1')}
								required
								defaultValue={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<Box sx={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: 3
							}}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										label={t('event.modal.datepicker.start')}
										value={startDate}
										onChange={onStartDateChange}
									/>
									<DateTimePicker
										label={t('event.modal.datepicker.end')}
										value={endDate}
										onChange={onEndDateChange}
									/>
								</LocalizationProvider>
							</Box>
							<TextField
								label={t('event.modal.textfields.textfield2')}
								multiline
								rows={5}
								defaultValue={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							<Button
								variant='contained'
								color='teal'
								type='submit'
							>
								{t('event.modal.button')}
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>
		</ThemeProvider>
	);
}

export default connect(null, { postEvent, editEvent })(EventModal);