export const devs = {
	deyak: {
		img: require('../img/backender.jpg'),
		name: 'Eugene Deiak',
		specialization: 'Back-end developer',
		bio: 'I am a web developer with nearly 1,5 year of experience. During this time, I have developed and managed projects for Mageap using Django and Django REST. I also worked as a problem solver where I solved algorithmic problems, and the last work was related to ML and data parsing'
	},
	svir: {
		img: require('../img/frontender.jpg'),
		name: 'Kyrylo Svir',
		specialization: 'Front-end developer',
		bio: 'I am a web developer with 0,5 year of experience, during which I did multiple pet-projects.'
	}
};