import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, ThemeProvider, Typography } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HomeIcon from '@mui/icons-material/Home';
import RoomIcon from '@mui/icons-material/Room';
import { defaultTheme } from '../../MUI/themes';
import { advantageStyle } from '../../MUI/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = ({ setOpen }) => {
	const { t } = useTranslation();

	useEffect(() => {
		setOpen(false);
	})

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				display: 'flex',
				flexDirection: { xs: 'column', md: 'row' },
				alignItems: 'center',
				justifyContent: 'center',
				gap: 5,
				mt: 9,
				py: 5,
			}}>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2
				}}>
					<Typography
						sx={{
							fontWeight: 500,
							fontSize: {
								xs: '2rem',
								sm: '2.25rem',
								md: '2.5rem',
								lg: '2.75rem',
								xl: '3rem'
							},
						}}
					>
						{t('home.header')}
					</Typography>
					<Typography
						sx={{
							color: 'teal.main',
							fontSize: {
								xs: '1.25rem',
								sm: '1.5rem',
								md: '1.75rem',
								lg: '2rem',
								xl: '2.25rem'
							},
						}}
					>
						{t('home.advantages.title')}
					</Typography>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5
					}}>
						<Box sx={advantageStyle}>
							<HomeIcon />
							<Typography variant='h6'>
								{t('home.advantages.advantage1')}
							</Typography>
						</Box>
						<Box sx={advantageStyle}>
							<FastForwardIcon />
							<Typography variant='h6'>
								{t('home.advantages.advantage2')}
							</Typography>
						</Box>
						<Box sx={advantageStyle}>
							<LightbulbIcon />
							<Typography variant='h6'>
								{t('home.advantages.advantage3')}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					gap: 2
				}}>
					<Box
						component='img'
						src={require('./img/Map.png')}
						alt='Map'
						sx={{
							width: '100%',
						}}
					/>
					<Button component={Link}
						to='/events'
						color='teal'
						variant='contained'
						sx={{
							maxWidth: 200,
							textAlign: 'center',
							fontSize: {
								xs: '0.65rem',
								sm: '0.75rem',
								md: '0.775rem',
							},
						}}
						endIcon={<RoomIcon />}
					>
						{t('home.start')}
					</Button>
				</Box>
			</Container>
		</ThemeProvider >
	);
}

export default connect(null)(Home);