import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, CardActions, Button, CardMedia, Rating, ThemeProvider, CardActionArea } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { connect } from 'react-redux';
import { deleteEvent } from '../../actions/map';
import EventModal from '../EventModal/EventModal';
import { useTranslation } from 'react-i18next';
const Event = ({
	eid,
	title,
	image,
	reviews,
	totalReviews,
	rating,
	description,
	start,
	end,
	creator,
	handleClick,
	user,
	deleteEvent,
}) => {
	const { t } = useTranslation();
	const [commentModalOpen, setCommentModalOpen] = useState(false);

	const onChangeClick = () => {
		setCommentModalOpen(true);
	}

	const onDeleteClick = () => {
		deleteEvent(eid);
	}

	const authorButtons = () => {
		return (
			<Box sx={{
				display: 'flex',
				gap: 1
			}}>
				<Button
					variant='contained'
					color='success'
					onClick={onChangeClick}
					size='small'
				>
					{t('event.buttons.change')}
				</Button>
				<Button
					variant='contained'
					color='error'
					onClick={onDeleteClick}
					size='small'
				>
					{t('event.buttons.delete')}
				</Button>
			</Box>
		);
	}

	return (
		<Box sx={{ minWidth: 275 }}>
			<ThemeProvider theme={defaultTheme}>
				{
					commentModalOpen ? <EventModal
						modalOpen={commentModalOpen}
						setModalOpen={setCommentModalOpen}
						eid={eid}
						eventTitle={title}
						eventImage={image}
						eventDescription={description}
						eventStart={start}
						eventEnd={end}
						isChanged={true}
					/> : <></>
				}

				<Card sx={{ boxShadow: 2 }}>
					<CardActionArea onClick={() => handleClick(eid, title, image, reviews, totalReviews, rating, description, start, end, creator)}>
						<CardMedia
							component='img'
							image={image}
							title='Event'
							sx={{
								height: '190px',
								width: '100%',
								objectFit: 'fill',
							}}
						/>
						<CardContent>
							<Typography sx={{ pb: 2 }} variant='body1' color='text.secondary' gutterBottom>
								{title}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '.15rem',
								}}
							>
								<Rating name='read-only' value={rating} readOnly precision={0.5} />
								<Typography>
									{rating?.toFixed(1)}
								</Typography>
								<Typography sx={{ ml: 1 }} color='text.secondary' variant='body2'>
									({totalReviews} {t('event.reviews')})
								</Typography>
							</Box>
						</CardContent>
					</CardActionArea>
					<CardActions sx={{
						mb: 1.25,
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5,
						justifyContent: 'center',
					}}>
						<Button variant='contained' color='teal' onClick={() => handleClick(eid, title, image, reviews, totalReviews, rating, description, start, end, creator)}>
							{t('event.buttons.additional')}
						</Button>
						{
							user?.id === creator ?
								authorButtons() :
								<></>
						}
					</CardActions>
				</Card>
			</ThemeProvider>
		</Box>
	);
}
const mapStateToProps = state => ({
	user: state.auth.user,
	editEventSuccess: state.map.editEventSuccess,
});

export default connect(mapStateToProps, { deleteEvent })(Event);