import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import axios from "axios";
import icon from '../layout/google-icon.svg';
import { Box, Button, Container, IconButton, InputAdornment, TextField, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { linkStyle } from '../../MUI/styles';
import { useTranslation } from 'react-i18next';

const API_URL = 'http://ec2-13-51-55-7.eu-north-1.compute.amazonaws.com';

const Login = ({ login, isAuthenticated, loginError, setOpen }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		if (loginError) {
			setErrorMessage(t('errors.login'));
		}
	}, [loginError, t]);

	useEffect(() => {
		setOpen(false);
	})

	useEffect(() => {
		setErrorMessage('');
	}, [email, password]);

	const onSubmit = e => {
		e.preventDefault();

		login(email, password);
	};

	const continueWithGoogle = async e => {
		try {
			const response = await axios.get(`${API_URL}/api/auth/o/google-oauth2/?redirect_uri=${API_URL}`);
			window.location.replace(response.data.authorization_url);
		} catch (err) {
			setErrorMessage(t('errors.authorization'))
		}
	}

	if (isAuthenticated) {
		return <Navigate to='/' />
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				pt: 12,
				pb: 3,
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<Box sx={{
					width: 350,
					boxShadow: 15,
					py: 5,
					px: 3,
					mx: 2,
				}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 1
					}}>
						<Typography sx={{ fontWeight: 500 }} variant='h4'>
							{t('login.title')}
						</Typography>
						<Typography variant='body2' color='text.secondary'>
							{t('login.subtitle')}
						</Typography>
					</Box>
					<Box>
						<form className='form form-login' onSubmit={onSubmit}>
							<TextField
								type='email'
								label={t('login.form.textfield1')}
								defaultValue={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								error={errorMessage ? true : false}
								helperText={errorMessage}
								size='small'
							/>
							<TextField
								type={showPassword ? 'text' : 'password'}
								label={t('login.form.textfield2')}
								defaultValue={password}
								onChange={(e) => setPassword(e.target.value)}
								InputProps={{
									endAdornment:
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
								}}
								required
								error={errorMessage ? true : false}
								helperText={errorMessage}
								size='small'
							/>
							<Button size='small' color='teal' variant='contained' type='submit'>
								{t('login.form.button')}
							</Button>
						</form>
					</Box>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5,
						mb: 2,
					}}>
						<Typography variant='body3' color='text.secondary'>
							{t('login.additional.register.title')}
							<Typography
								sx={linkStyle}
								component={Link}
								variant='body2'
								to='/register'
							>
								{t('login.additional.register.link')}
							</Typography>
						</Typography>
						<Typography variant='body3' color='text.secondary'>
							{t('login.additional.resetPassword.title')}
							<Typography
								sx={linkStyle}
								component={Link}
								variant='body2'
								to='/reset-password'
							>
								{t('login.additional.resetPassword.link')}
							</Typography>
						</Typography>
						<Button size='small' type='submit' variant='outlined' color='dark' onClick={continueWithGoogle}>
							<Box sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 0.25
							}}>
								<img src={icon} alt='' />
								{t('login.additional.google')}
							</Box>
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	loginError: state.auth.loginError
});

export default connect(mapStateToProps, { login })(Login);