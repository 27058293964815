import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';

import icon from '../layout/google-icon.svg';
import '../layout/layout.css';
import { connect } from 'react-redux';
import { signup } from '../../actions/auth';
import axios from 'axios';
import { Box, Button, Container, IconButton, InputAdornment, TextField, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { linkStyle } from '../../MUI/styles';
import { useTranslation } from 'react-i18next';

const API_URL = 'http://ec2-13-51-55-7.eu-north-1.compute.amazonaws.com';

const Signup = ({ signup, isAuthenticated, registrationError, setOpen }) => {
	const { t } = useTranslation();
	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState(false);
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [matchPassword, setMatchPassword] = useState('');
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {
		setOpen(false);
	})

	useEffect(() => {
		if (registrationError) {
			if (registrationError.username) {
				setErrorMessage(registrationError.username[0]);
				setUsernameError(true);
			} else if (registrationError.password) {
				setErrorMessage(registrationError.password[0]);
				setPasswordError(true);
			} else {
				setErrorMessage(registrationError.email[0]);
				setEmailError(true);
			}
		} else if (submitted && !passwordError && !usernameError && !emailError) {
			setSuccess(true);
		}
	}, [registrationError, submitted, passwordError, usernameError, emailError]);

	useEffect(() => {
		setErrorMessage('');
		setUsernameError(false);
		setEmailError(false);
		setPasswordError(false);
	}, [username, email, password, matchPassword]);

	const onSubmit = async e => {
		e.preventDefault();

		if (password === matchPassword) {
			await signup(username, email, password, matchPassword);
		}
		else {
			setErrorMessage(t('errors.passwordMatch'));
			setPasswordError(true);
		}

		setSubmitted(true);
	};

	const continueWithGoogle = async e => {
		try {
			const response = await axios.get(`${API_URL}/api/auth/o/google-oauth2/?redirect_uri=${API_URL}`);
			window.location.replace(response.data.authorization_url);
		} catch (err) {
			setErrorMessage(t('errors.authorization'))
		}
	}

	if (isAuthenticated) {
		return <Navigate to='/' />
	}
	if (success) {
		return <Navigate to='/login' />
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				pt: 12,
				pb: 3,
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<Box sx={{
					width: 350,
					boxShadow: 15,
					py: 4,
					px: 3,
					mx: 2,
				}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 1
					}}>
						<Typography sx={{ fontWeight: 500 }} variant='h4'>
							{t('register.title')}
						</Typography>
						<Typography variant='body2' color='text.secondary'>
							{t('register.subtitle')}
						</Typography>
					</Box>
					<Box>
						<form className='form' onSubmit={onSubmit}>
							<TextField
								type='text'
								label={t('register.textfields.textfield1')}
								defaultValue={username}
								onChange={(e) => setUsername(e.target.value)}
								required
								error={usernameError}
								helperText={usernameError ? errorMessage : ''}
								size='small'
							/>
							<TextField
								type='email'
								label={t('register.textfields.textfield2')}
								defaultValue={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								error={emailError}
								helperText={emailError ? errorMessage : ''}
								size='small'
							/>
							<TextField
								type={showPassword ? 'text' : 'password'}
								label={t('register.textfields.textfield3')}
								defaultValue={password}
								onChange={(e) => setPassword(e.target.value)}
								minLength='8'
								InputProps={{
									endAdornment:
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword(!showPassword)}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
								}}
								required
								error={passwordError}
								helperText={passwordError ? errorMessage : ''}
								size='small'
							/>
							<TextField
								type={showPassword ? 'text' : 'password'}
								label={t('register.textfields.textfield4')}
								defaultValue={matchPassword}
								onChange={(e) => setMatchPassword(e.target.value)}
								minLength='8'
								required
								error={passwordError}
								helperText={passwordError ? errorMessage : ''}
								size='small'
							/>
							<Button size='small' color='teal' variant='contained' type='submit'>
								{t('register.button')}
							</Button>
						</form>
					</Box>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1.5,
						mb: 2,
					}}>
						<Typography variant='body3' color='text.secondary'>
							{t('register.additional.login.title')}
							<Typography
								sx={linkStyle}
								component={Link}
								variant='body2'
								to='/login'
							>
								{t('register.additional.login.link')}
							</Typography>
						</Typography>
						<Button size='small' type='submit' variant='outlined' color='dark' onClick={continueWithGoogle}>
							<Box sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 0.25
							}}>
								<img src={icon} alt='' />
								{t('register.additional.google')}
							</Box>
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	registrationError: state.auth.registrationError
});

export default connect(mapStateToProps, { signup })(Signup);