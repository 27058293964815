import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { verify } from "../../actions/auth";
import '../layout/layout.css';

import { Box, Button, Container, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { useTranslation } from "react-i18next";

const Activate = (props) => {
	const { t } = useTranslation();
	const [success, setSuccess] = useState(false);
	const { uid, token } = useParams();

	const verifyAccount = (e) => {
		props.verify(uid, token);
		setSuccess(true);
	}
	if (success) {
		return <Navigate to='/' />
	}
	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				pt: 12,
				pb: 3,
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
			}}>
				<Box sx={{
					width: 275,
					boxShadow: 15,
					py: 4,
					px: 3,
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
				}}>
					<Typography sx={{ fontWeight: 500 }} variant='h5'>
						{t('verify.title')}
					</Typography>
					<Button size='small' color='teal' variant='contained' type='button' onClick={verifyAccount}>
						{t('verify.button')}
					</Button>
				</Box>
			</Container>
		</ThemeProvider>
	);
}

export default connect(null, { verify })(Activate);