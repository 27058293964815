import axios from 'axios';
import {
	PROFILE_EDITED_SUCCESS,
	PROFILE_EDITED_FAIL,
	RESET_PROFILE_EDITED_SUCCESS,

	GET_USER_INFO_SUCCESS,
	GET_USER_INFO_FAIL,
} from './types';
const API_URL = 'http://ec2-13-51-55-7.eu-north-1.compute.amazonaws.com/api';

export const getUserInfo = (uid) => async dispatch => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.get(`${API_URL}/profiles/${uid ?? 'me'}/`, config);

			dispatch({
				type: GET_USER_INFO_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: GET_USER_INFO_FAIL,
				payload: err.response.data
			});
		}
	} else {
		dispatch({
			type: GET_USER_INFO_FAIL
		});
	}
}

export const getReviewerInfo = async (uid) => {
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		try {
			const response = await axios.get(`${API_URL}/profiles/${uid}/`, config);
			return response.data;
		} catch (err) {
			console.log('ERROR', err);
			return null;
		}
	} else {
		return null;
	}
};

export const profileEdit = (uid, bio, avatar, username) => async dispatch => {
	
	if (localStorage.getItem('access')) {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `JWT ${localStorage.getItem('access')}`
			}
		};

		const formData = new FormData();
		formData.append('bio', bio);
		if (avatar) {
			formData.append('avatar', avatar);
		}
		if (username) {
			formData.append('username', username);
		}

		try {
			const response = await axios.patch(`${API_URL}/profiles/${uid}/`, formData, config);
			dispatch({
				type: PROFILE_EDITED_SUCCESS,
				payload: response.data
			});
		} catch (err) {
			dispatch({
				type: PROFILE_EDITED_FAIL
			});
		}
	} else {
		dispatch({
			type: PROFILE_EDITED_FAIL
		});
	}
}

export const resetProfileEditedSuccess = () => {
	return {
		type: RESET_PROFILE_EDITED_SUCCESS
	};
}