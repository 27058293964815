import React, { useState } from 'react';
import { Box, Typography, Rating, ThemeProvider, IconButton, Menu, MenuItem } from '@mui/material';
import { defaultTheme } from '../../../MUI/themes';
import { connect } from 'react-redux';
import { MoreVert } from '@mui/icons-material';
import EventModal from '../../EventModal/EventModal';
import { deleteEvent } from '../../../actions/map';
import { useTranslation } from 'react-i18next';
const EventItem = ({
	eid,
	image,
	title,
	rating,
	description,
	startDate,
	endDate,
	deleteEvent,
}) => {
	const { t } = useTranslation();
	const [commentModalOpen, setCommentModalOpen] = useState(false);
	const start = new Date(startDate);
	const end = new Date(endDate);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChangeClick = () => {
		setCommentModalOpen(true);
	}
	const handleDeleteClick = () => {
		deleteEvent(eid);
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			{
				commentModalOpen ? <EventModal
					modalOpen={commentModalOpen}
					setModalOpen={setCommentModalOpen}
					eid={eid}
					eventTitle={title}
					eventImage={image}
					eventDescription={description}
					eventStart={startDate}
					eventEnd={endDate}
					isChanged={true}
				/> : <></>
			}
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					flexDirection: {
						xs: 'column',
						sm: 'row',
					},
					width: '100%',
					bgcolor: 'white.dark',
					p: 2,
					mx: 2,
					gap: 2,
				}}
			>
				<Box
					component='img'
					alt='Event'
					sx={{
						height: { xs: '100%', sm: 200 },
						width: { xs: '100%', sm: 300 },
						maxHeight: { xs: 200, sm: '100%' },
						maxWidth: { xs: '100%', sm: 200 },
						objectFit: 'cover',
					}}
					src={image}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: .75,
						width: {
							xs: '100%',
							sm: '40%',
							md: '60%',
						},
					}}
				>
					<Typography
						variant='h4'
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							fontSize: {
								xs: '1.25rem',
								sm: '1.5rem',
								md: '1.75rem',
								lg: '2rem',
							},
							fontWeight: 425,
						}}
					>
						{title}
					</Typography>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '.15rem',
						}}
					>
						<Rating name='read-only' value={rating} readOnly precision={0.5} />
						<Typography sx={{
							fontSize: {
								xs: '1rem',
								lg: '1.25rem',
							},
						}}>
							{rating?.toFixed(1)}
						</Typography>
					</Box>
					<Typography
						variant='h6'
						color='text.secondary'
						sx={{
							overflow: 'auto',
							height: '50%',
							'&::-webkit-scrollbar': {
								height: '.5rem',
								width: '.5rem',
							},
							'&::-webkit-scrollbar-track': {
								boxShadow: 'inset 0 0 5px grey',
								borderRadius: '.5rem',
							},
							'&::-webkit-scrollbar-thumb': {
								background: 'darkgrey',
								borderRadius: '.5rem',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								background: '#636363',
							},
							fontSize: {
								xs: '.85rem',
								sm: '1.05rem',
								md: '1.2rem',
								lg: '1.35rem',
							},
							fontWeight: 400,
						}}
					>
						{description}
					</Typography>
					<Typography
						variant='body1'
						sx={{
							fontSize: {
								xs: '.75rem',
								sm: '.9rem',
								md: '1rem',
								lg: '1.1rem',
							},
						}}
					>
						{start.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })} - {end.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })}
					</Typography>
				</Box>
				<IconButton
					sx={{
						position: 'absolute',
						right: 16,
					}}
					onClick={handleClick}
				>
					<MoreVert />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<MenuItem onClick={handleChangeClick}>
						{t('event.buttons.change')}
					</MenuItem>
					<MenuItem onClick={handleDeleteClick}>
						{t('event.buttons.delete')}
					</MenuItem>
				</Menu>
			</Box>
		</ThemeProvider>
	);
}

export default connect(null, { deleteEvent })(EventItem);