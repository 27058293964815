import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Container, ThemeProvider, Snackbar, Alert, Pagination, Stack, Tab } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { loadMyEvents, resetEditEventSuccess, resetDeleteEventSuccess } from '../../actions/map';
import EventItem from '../../components/Event/EventItem.js/EventItem';
import EventItemSkeleton from '../../components/Event/EventItem.js/EventItemSkeleton';
import { TabContext, TabList } from '@mui/lab';
import { Navigate } from 'react-router-dom';
import MyEventSearch from '../../components/MyEventSearch/MyEventSearch';
import { useTranslation } from 'react-i18next';


const MyEvents = ({
	loadMyEvents,
	myEvents,
	setOpen,
	editEventSuccess,
	deleteEventSuccess,
	resetEditEventSuccess,
	resetDeleteEventSuccess,
	isAuthenticated,
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarText, setSnackbarText] = useState('');
	const [tabValue, setTabValue] = useState('active');
	const [isExpired, setIsExpired] = useState(false);
	const [searchText, setSearchText] = useState('');

	const handleTabChange = (event, newValue) => {
		if (newValue === tabValue) {
			return;
		}

		setTabValue(newValue);

		if (newValue === 'active') setIsExpired(false);
		else if (newValue === 'inactive') setIsExpired(true);
		else setIsExpired(null);

		setPage(1);
		setLoading(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	const handlePaginationChange = (event, value) => {
		setLoading(true);
		const fetchData = async () => {
			await loadMyEvents(value, isExpired, searchText);
		};

		fetchData();
		setPage(value);
	};

	useEffect(() => {
		setOpen(false);
	})

	useEffect(() => {
		loadMyEvents(page, isExpired, searchText);
	}, [loadMyEvents, page, isExpired, searchText])

	useEffect(() => {
		if (myEvents) {
			setLoading(false);
		}
	}, [myEvents])

	useEffect(() => {
		if (editEventSuccess || deleteEventSuccess) {
			setLoading(true);
			const fetchData = async () => {
				await loadMyEvents(page, isExpired, searchText);
			};
			const handleActionSuccess = async () => {
				setSnackbarText(`Event ${editEventSuccess ? 'edited' : 'deleted'} successfully!`);

				if (editEventSuccess) resetEditEventSuccess();
				else resetDeleteEventSuccess();

				setOpenSnackbar(true);
			}
			fetchData();
			handleActionSuccess();
		}
	}, [editEventSuccess, deleteEventSuccess, resetEditEventSuccess, resetDeleteEventSuccess, loadMyEvents, page, isExpired, searchText])

	if (isAuthenticated === false) {
		return <Navigate to='/login/' />
	}

	return (
		<Container sx={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			mt: { xs: 9, sm: 12 },
		}}>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success"
					sx={{ width: '100%' }}
				>
					{snackbarText}
				</Alert>
			</Snackbar>
			<ThemeProvider theme={defaultTheme}>
				<Box
					sx={{
						width: '75%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 2,
					}}
				>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}>
						<TabContext value={tabValue}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleTabChange}>
									<Tab
										label={t('events.tabs.active')}
										value="active"
									/>
									<Tab
										label={t('events.tabs.inactive')}
										value="inactive"
									/>
									<Tab
										label={t('events.tabs.all')}
										value="all"
									/>
								</TabList>
							</Box>
						</TabContext>

						<MyEventSearch
							searchText={searchText}
							setSearchText={setSearchText}
						/>

						<Stack
							spacing={2}
							sx={{ width: '100%' }}
						>
							{(loading ? Array.from(new Array(5)) : (myEvents?.results || [])).map((myEvent, idx) =>
								myEvent ? (
									<EventItem
										key={myEvent.id}
										eid={myEvent.id}
										title={myEvent.title}
										image={myEvent.image}
										rating={myEvent.rating}
										description={myEvent.description}
										startDate={myEvent.start_date}
										endDate={myEvent.end_date}
									/>
								) : (
									<EventItemSkeleton key={idx} />
								)
							)}
						</Stack>
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							width: '100%',
							pt: 2.5,
							pb: 7.5,
						}}
					>
						<Pagination
							count={myEvents ? Math.ceil(myEvents.count / 5) : 1}
							shape='rounded'
							color='teal'
							page={page}
							onChange={handlePaginationChange}
						/>
					</Box>
				</Box>
			</ThemeProvider>
		</Container>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	myEvents: state.map.myEvents,
	editEventSuccess: state.map.editEventSuccess,
	deleteEventSuccess: state.map.deleteEventSuccess,
});

export default connect(mapStateToProps, { loadMyEvents, resetEditEventSuccess, resetDeleteEventSuccess })(MyEvents);