import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/auth';
import '../layout/layout.css';

import { Box, Button, Container, TextField, ThemeProvider, Typography } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { useTranslation } from 'react-i18next';


const ResetPassword = ({ resetPassword, setOpen }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setOpen(false);
	})

	const onSubmit = e => {
		e.preventDefault();

		resetPassword(email);
		setSuccess(true);
	};

	if (success) {
		return <Navigate to='/' />
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				pt: 12,
				pb: 3,
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<Box sx={{
					width: 350,
					boxShadow: 15,
					py: 5,
					px: 3,
					textAlign: 'center',
				}}>
					<Typography sx={{ fontWeight: 800 }} variant='h5'>
						{t('resetPassword.title')}
					</Typography>
					<form className='form' onSubmit={onSubmit}>
						<TextField
							type='email'
							label={t('resetPassword.textfield1')}
							defaultValue={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							size='small'
						/>
						<Button size='small' color='teal' variant='contained' type='submit'>
							{t('resetPassword.button')}
						</Button>
					</form>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default connect(null, { resetPassword })(ResetPassword);

