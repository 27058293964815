import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

const store = createStore(
	rootReducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

/*  REDUX TOOLKIT
	import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
	import rootReducer from "./reducers";

	const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({
		thunk: true, // This enables Redux Toolkit's built-in thunk middleware
	}),
	devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
	});

	export default store;
*/