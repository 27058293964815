import React, { forwardRef, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useMap } from 'react-leaflet';
import { latLng } from 'leaflet';
import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.8),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.9),
	},
	left: '50%',
	transform: 'translateX(-50%)',
	marginTop: theme.spacing(7.5),
	zIndex: 999,
	width: '100%',
	[theme.breakpoints.up('xs')]: {
		width: '75%',
	},
	[theme.breakpoints.up('sm')]: {
		width: 'auto',
		minWidth: '40ch',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	flexGrow: 1,
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
	},
}));

const StyledList = styled(List)(({ theme }) => ({
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		maxWidth: '40ch',
	},
}));

const SearchBar = forwardRef(({ markers, onSelectEvent }, ref) => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState('');
	const [filteredMarkers, setFilteredMarkers] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const map = useMap();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		const filteredData = markers?.filter(marker =>
			marker.title.toLowerCase().includes(searchText.toLowerCase())
		);

		setFilteredMarkers(filteredData);
	}, [searchText, markers]);

	return (
		<Search ref={ref}>
			<Box sx={{
				display: 'flex',
				position: 'relative',
			}}>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<StyledInputBase
					placeholder={t('searchbar.placeholder')}
					anchorEl={anchorEl}
					inputProps={{ 'aria-label': 'search' }}
					onChange={(e) => setSearchText(e.target.value)}
					onClick={(e) => {
						e.stopPropagation();
						handleClick(e);
					}}
				/>
			</Box>
			{
				filteredMarkers?.length > 0 && searchText && (<StyledList>
					{
						filteredMarkers?.map((marker, index) => (
							<React.Fragment key={marker.id}>
								<ListItemButton
									onClick={() => onSelectEvent(marker.id, latLng(marker.position_x, marker.position_y), map)}
								>
									<ListItem alignItems="flex-start">
										<ListItemAvatar>
											<Avatar
												sx={{
													boxShadow: 2,
												}}
												alt="Event"
												src={marker.image}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={marker.title}
											secondary={
												<>
													<Typography
														sx={{ display: 'inline' }}
														component="span"
														variant="body2"
														color="text.primary"
													>
														{marker.title}
													</Typography>
													{" — I'll be in your neighborhood doing errands this…"}
												</>
											}
										/>
									</ListItem>
								</ListItemButton>
								<Divider variant="inset" component="li" />
							</React.Fragment>
						))}
				</StyledList>
				)
			}
		</Search >
	);
});

export default SearchBar;