import React, { useEffect, useState } from 'react';
import { Box, Button, Rating, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import { postReview } from '../../actions/map';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Review = ({ open, setOpen, eid, title, ratingValue, postReview }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(2.0);
	const [comment, setComment] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const handlePost = () => {
		postReview(eid, comment, value);
		handleClose();
	};

	useEffect(() => {
		setValue(ratingValue);
	}, [ratingValue])

	return (
		<ThemeProvider theme={defaultTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
			>
				<DialogTitle>
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('review.subtitle')}
					</DialogContentText>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 2,
						mt: 2
					}}>
						<Rating
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							size='large'
						/>
						<TextField
							label={t('review.textfield1')}
							multiline
							fullWidth
							rows={4}
							onChange={e => setComment(e.target.value)}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color='teal' variant='outlined' onClick={handleClose}>
						{t('review.buttons.cancel')}
					</Button>
					<Button color='teal' variant='contained' onClick={handlePost} autoFocus disabled={value === null}>
						{t('review.buttons.post')}
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}

export default connect(null, { postReview })(Review);