import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Button, CardMedia, Rating, ThemeProvider, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Comment from '../Comment/Comment';
import { ExpandMore, RateReviewOutlined } from '@mui/icons-material';
import Review from '../Review/Review';
import { connect } from 'react-redux';
import { loadReviews, resetDeleteReviewSuccess, resetPostReviewSuccess } from '../../actions/map';
import { useTranslation } from 'react-i18next';

const FullEvent = ({
	eid,
	title,
	image,
	rating,
	description,
	start,
	end,
	loadReviews,
	reviews,
	creator,
	user,
	postReviewSuccess,
	deleteReviewSuccess,
	editReviewSuccess,
}) => {
	const { t } = useTranslation();
	const startDate = new Date(start);
	const endDate = new Date(end);
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(0.0);
	const [passedValue, setPassedValue] = useState(2.0);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleRatingClick = (event, newValue) => {
		setPassedValue(Math.ceil(newValue));
		setValue(value);
		handleClickOpen();
	}

	useEffect(() => {
		const fetchData = async (id) => {
			await loadReviews(id);
		};

		if (eid) {
			fetchData(eid);
		}
	}, [loadReviews, eid, postReviewSuccess, deleteReviewSuccess, editReviewSuccess]);

	useEffect(() => {
		setValue(rating);
	}, [rating])

	return (
		<Box sx={{ width: 325, display: 'flex', flexDirection: 'column' }}>
			<ThemeProvider theme={defaultTheme}>
				<Card>
					<CardMedia
						component='img'
						sx={{
							height: '190px',
							width: '100%',
							objectFit: 'fill',
						}}
						image={image}
						title='FullEvent'
					/>
					<CardContent>
						<Typography sx={{ pb: 0.5 }} variant='h5' gutterBottom>
							{title}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '.15rem',
							}}
						>
							<Rating
								value={value}
								precision={0.5}
								onChange={handleRatingClick}
							/>
							<Typography>
								{rating?.toFixed(1)}
							</Typography>
						</Box>
					</CardContent>
					<Divider />
					<Box sx={{ display: 'flex', p: 1 }}>
						<AccessTimeIcon />
						<Box sx={{ ml: 1 }}>
							<Typography variant='h6' color='text.secondary'>
								{t('event.datepicker.start')}: {startDate.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
							</Typography>
							<Typography variant='h6' color='text.secondary'>
								{t('event.datepicker.end')}: {endDate.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
							</Typography>
						</Box>
					</Box>
					<Divider />
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
						>
							<Typography variant='h6'>
								{t('event.accordion.description')}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography> {description} </Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant='h6'>
								{t('event.accordion.reviews')}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{
								reviews?.map(review => {
									return (
										<>
											<Comment
												id={review.id}
												key={review.id}
												comment={review.comment}
												creationDate={review.created_at}
												rating={review.rating}
												author={review.profile}
											/>
										</>
									);
								})
							}
						</AccordionDetails>
					</Accordion>
					<Box sx={{
						display: 'flex',
						justifyContent: 'center',
						p: 2
					}}>
						<Button
							color='teal'
							variant='outlined'
							onClick={handleClickOpen}
							startIcon={<RateReviewOutlined />}
						>
							{t('event.buttons.reviews')}
						</Button>
						<Review
							open={open}
							setOpen={setOpen}
							title={title}
							eid={eid}
							ratingValue={passedValue}
						/>
					</Box>
					{
						/* user.id === creator ? <Button>Change</Button> : <></> */
					}
				</Card>
			</ThemeProvider>
		</Box>
	);
}

const mapStateToProps = state => ({
	reviews: state.map.reviews,
	postReviewSuccess: state.map.postReviewSuccess,
	deleteReviewSuccess: state.map.deleteReviewSuccess,
	editReviewSuccess: state.map.editReviewSuccess,
	user: state.auth.user,
});

export default connect(mapStateToProps, { loadReviews, resetPostReviewSuccess, resetDeleteReviewSuccess })(FullEvent);