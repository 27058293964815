import React, { useState, useEffect, useRef } from 'react';
import Event from '../../components/Event/Event';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Icon, divIcon } from 'leaflet';
import { connect } from 'react-redux';
import { loadEvents, resetDeleteEventSuccess, resetDeleteReviewSuccess, resetEditEventSuccess, resetEditReviewSuccess, resetPostEventSuccess, resetPostReviewSuccess } from '../../actions/map';
import 'leaflet/dist/leaflet.css';
import './Events.css';
import { Navigate } from 'react-router-dom';
import { Alert, Box, Button, Divider, Drawer, IconButton, Snackbar, ThemeProvider } from '@mui/material';
import { ChevronLeft, LocationSearching } from '@mui/icons-material';
import { DrawerHeader } from '../../MUI/styles';
import FullEvent from '../../components/Event/FullEvent';
import { defaultTheme } from '../../MUI/themes';
import EventModal from '../../components/EventModal/EventModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';

const Events = ({
	open,
	setOpen,
	isAuthenticated,
	loadEvents,
	events,

	postEventSuccess,
	resetPostEventSuccess,
	deleteEventSuccess,
	resetDeleteEventSuccess,
	editEventSuccess,
	resetEditEventSuccess,

	deleteReviewSuccess,
	editReviewSuccess,
	postReviewSuccess,
	resetPostReviewSuccess,
	resetDeleteReviewSuccess,
	resetEditReviewSuccess
}) => {
	const { t } = useTranslation();
	const [markers, setMarkers] = useState([]);
	const [currentEvent, setCurrentEvent] = useState({});
	const [position, setPosition] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [eventPosition, setEventPosition] = useState(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [textSnackbar, setTextSnackbar] = useState('');
	const [severity, setSeverity] = useState('success');
	const searchBarRef = useRef(null);
	const markerRefs = useRef(new Map());

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackbar(false);
	};

	const markerIcon = new Icon({
		iconUrl: require('./img/marker-icon.png'),
		iconSize: [38, 38]
	});

	const createMarkerClusterIcon = (cluster) => {
		return new divIcon({
			html: `<span>${cluster.getChildCount()}</span>`,
			className: 'marker',
			iconSize: [38, 38]
		});
	}

	const handleClick = (
		eid,
		title,
		image,
		reviews,
		totalReviews,
		rating,
		description,
		start,
		end,
		creator
	) => {
		setCurrentEvent({
			eid,
			title,
			image,
			reviews,
			totalReviews,
			rating,
			description,
			start,
			end,
			creator
		});
		setOpen(true);
	}

	const LocateButton = () => {
		const positionMarkerIcon = new Icon({
			iconUrl: require('./img/current-location.png'),
			iconSize: [29, 29]
		});
		const buttonRef = useRef(null);

		const map = useMapEvents({
			click(e) {
				if (open) {
					setOpen(false);
					return;
				}
				if ((buttonRef.current && buttonRef.current.contains(e.originalEvent.target)) ||
					(searchBarRef.current && searchBarRef.current.contains(e.originalEvent.target))) {
					return;
				}
				setEventPosition(e.latlng);
				setModalOpen(true);
			},
			locationfound(e) {
				setPosition(e.latlng);
				map.flyTo(e.latlng, map.getZoom());
			},
		})

		return (
			<>
				<ThemeProvider theme={defaultTheme}>
					<Button
						ref={buttonRef}
						sx={{
							position: 'absolute',
							zIndex: 400,
							right: 20,
							top: 20,
						}}
						variant='contained'
						color='white'
						onClick={() => map.locate()}
					>
						<LocationSearching />
					</Button>
				</ThemeProvider>
				{
					position && (
						<Marker
							icon={positionMarkerIcon}
							position={position}
						>
							<Popup>
								{t('events.location')}
							</Popup>
						</Marker>
					)
				}
			</>
		);
	}

	const handleSearchResultClick = (id, position, map) => {
		const marker = markerRefs.current.get(id);
		if (marker) {
			map.flyTo(position, 14);
			marker.openPopup();
		}
	}

	useEffect(() => {
		setOpen(false);
		loadEvents();
	}, [loadEvents, postEventSuccess, deleteEventSuccess, editEventSuccess, postReviewSuccess, deleteReviewSuccess, editReviewSuccess, setOpen]);

	useEffect(() => {
		setMarkers(events);
	}, [events]);

	useEffect(() => {
		const handleActionSuccess = async () => {
			if (postReviewSuccess || deleteReviewSuccess || editReviewSuccess) {
				setSeverity('success');
				setTextSnackbar(t('comment.snackbar.success', {
					value: postReviewSuccess ? t('comment.snackbar.post')
						: deleteReviewSuccess ? t('comment.snackbar.delete')
							: t('comment.snackbar.edit')
				}));
				if (postReviewSuccess) resetPostReviewSuccess();
				else if (deleteReviewSuccess) resetDeleteReviewSuccess();
				else resetEditReviewSuccess();
				setOpenSnackbar(true);
			} else if (postReviewSuccess === false) {
				setSeverity('error');
				setTextSnackbar(t('comment.snackbar.fail'));
				resetPostReviewSuccess();
				setOpenSnackbar(true);
			}
		};

		handleActionSuccess();
	}, [deleteReviewSuccess, editReviewSuccess, postReviewSuccess, resetPostReviewSuccess, resetDeleteReviewSuccess, resetEditReviewSuccess, t])

	useEffect(() => {
		const handleActionSuccess = async () => {
			if (postEventSuccess || deleteEventSuccess || editEventSuccess) {
				setTextSnackbar(t('event.snackbar.success', {
					value: postEventSuccess ? t('event.snackbar.post')
						: deleteEventSuccess ? t('event.snackbar.delete')
							: t('event.snackbar.edit')
				}));
				if (postEventSuccess) resetPostEventSuccess();
				else if (deleteEventSuccess) resetDeleteEventSuccess();
				else resetEditEventSuccess();
				setOpenSnackbar(true);
			}
		};

		handleActionSuccess();
	}, [postEventSuccess, deleteEventSuccess, editEventSuccess, resetPostEventSuccess, resetDeleteEventSuccess, resetEditEventSuccess, t]);

	if (isAuthenticated === false) {
		return <Navigate to='/login' />
	}

	return (
		<Box sx={{
			mt: {
				xs: 7.25,
				sm: 8.5,
				md: 9
			},
		}}>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={severity}
					sx={{ width: '100%' }}
				>
					{textSnackbar}
				</Alert>
			</Snackbar>
			<Drawer
				sx={{
					position: 'absolute',
					width: 325,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: 325,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={() => setOpen(false)}>
						<ChevronLeft />
					</IconButton>
				</DrawerHeader>
				<Divider />
				{
					currentEvent ?
						<FullEvent {...currentEvent} /> :
						<></>
				}
			</Drawer>

			{
				modalOpen ? <EventModal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					positionX={eventPosition.lat}
					positionY={eventPosition.lng}
				/> : <></>
			}

			{<MapContainer center={[48.618531, 22.291568]} zoom={13}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>

				<LocateButton />

				<SearchBar
					ref={searchBarRef}
					markers={markers}
					onSelectEvent={handleSearchResultClick}
				/>


				<MarkerClusterGroup
					chunkedLoading
					iconCreateFunction={createMarkerClusterIcon}
				>
					{
						markers?.map(marker => {
							return (
								<>
									<Marker
										key={marker.id}
										position={[marker.position_x, marker.position_y]}
										icon={markerIcon}
										ref={(ref) => {
											if (ref) {
												markerRefs.current.set(marker.id, ref);
											}
										}}
									>
										<Popup>
											<Event
												eid={marker.id}
												title={marker.title}
												image={marker.image}
												reviews={marker.reviews}
												totalReviews={marker.total_reviews}
												rating={marker.rating}
												description={marker.description}
												start={marker.start_date}
												end={marker.end_date}
												creator={marker.user}
												handleClick={(id, title, image, reviews, totalReviews, rating, description, start, end, creator) => handleClick(id, title, image, reviews, totalReviews, rating, description, start, end, creator)}
											/>
										</Popup>
									</Marker>
								</>
							);
						})
					}
				</MarkerClusterGroup>
			</MapContainer>}
		</Box>
	);
}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
	events: state.map.events,

	postEventSuccess: state.map.postEventSuccess,
	deleteEventSuccess: state.map.deleteEventSuccess,
	editEventSuccess: state.map.editEventSuccess,

	postReviewSuccess: state.map.postReviewSuccess,
	deleteReviewSuccess: state.map.deleteReviewSuccess,
	editReviewSuccess: state.map.editReviewSuccess,
});

export default connect(mapStateToProps, {
	loadEvents,
	resetPostEventSuccess,
	resetDeleteEventSuccess,
	resetEditEventSuccess,
	resetPostReviewSuccess,
	resetDeleteReviewSuccess,
	resetEditReviewSuccess
})(Events);