import React, { useEffect } from 'react';
import { Container, Divider, Stack, ThemeProvider } from '@mui/material';
import { defaultTheme } from '../../MUI/themes';
import DeveloperInfo from '../../components/DeveloperInfo/DeveloperInfo';
import { devs } from '../../components/DeveloperInfo/info/info';

const AboutUs = ({ setOpen }) => {
	useEffect(() => {
		setOpen(false);
	})

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container sx={{
				display: 'flex',
				flexDirection: {
					xs: 'column',
					sm: 'column',
					md: 'row'
				},
				alignItems: 'center',
				justifyContent: 'center',
				pt: {
					xs: 10,
					sm: 12.5,
					md: 15,
					lg: 20,
				},
				pb: {
					xs: 5,
					sm: 6.25,
					md: 7.5,
					lg: 10,
				},
			}}>
				<Stack
					spacing={{
						xs: 2.5,
						sm: 5,
						md: 7.5
					}}
				>
					<DeveloperInfo developer={devs.deyak} />
					<Divider />
					<DeveloperInfo developer={devs.svir} reverse={true} />
				</Stack>
			</Container>
		</ThemeProvider>
	);
}

export default AboutUs;